import s from './howDoesThisWorkPage.module.scss';
import { Navigation } from '../../common/navigation/navigation';
import { PageContainer } from '../../common/pageContainer/pageContainer';
import { Section } from '../../common/section/section';
import { WhiteCard } from '../../common/whiteCard/whiteCard';
import { Button } from '../../common/button/button';
import { ReactComponent as ArrowFlag } from '../../../img/linkArrowFlag.svg';
import img from '../../../img/image.png';

export const HowDoesThisWork = () => {
	const weDo = [
		{
			title: 'Подбор юристов',
			description:
				'Самую важную часть работы составляет подбор юристов, так как от квалификации юриста зависит правильность документа, что влияет на решение судьи',
		},
		{
			title: 'Составление документов',
			description:
				'Наши профессиональные юристы, тщательно составляют гибкие документы, позволяющие при заполнении простой анкеты, максимально удовлетворять любые юридические потребности',
		},
		{
			title: 'Актуализация документов',
			description:
				'Постоянно обновлением документы под изменения законов РФ. Даты изменения документов можно отслеживать на странице каждого документа',
		},
		{
			title: 'Отправка документа',
			description:
				'Уникальное программное обеспечение с полной базой судов РФ, помогает точно определить подсудность и отправить документ в назначенный суд',
		},
		{
			title: 'Отслеживание документа',
			description:
				'В нашем личном кабинете можно полностью отследить движение документа, от его создания, до решения судьи',
		},
		{
			title: 'Круглосуточная поддержка',
			description:
				'По всем интересующим вопросам и проблемам в ходе работы с сервисом, Вы всегда можете обратиться в нашу круглосуточную службу поддержки',
		},
	];

	return (
		<PageContainer classes={s.container}>
			<Navigation
				linkList={[
					{
						text: 'Главная',
						path: '/',
					},
					{
						text: 'Как это работает',
					},
				]}
			/>

			<Section className={s.section}>
				<h3 className={`title`}>Всего 3 шага для вас</h3>

				<div className={s.cardList}>
					<div className={s.stepCard}>
						<img src={img} alt='img' />

						<h4>
							<span>1.</span> Выбрать нужный документ
						</h4>

						<p>
							В нашем каталоге собраны самые популярные документы, на все случаи
							жизни. Вы можете воспользоваться поиском или фильтрам по
							категориям. А так же нашим встроенным искусственным интеллектом,
							для поиска нужного документа
						</p>

						<Button
							classes={s.btn}
							text={
								<>
									Перейти в каталог
									<ArrowFlag />
								</>
							}
						/>
					</div>

					<div className={s.stepCard}>
						<img src={img} alt='img' />

						<h4>
							<span>2.</span> Перейти в каталог
						</h4>

						<p>
							Краткая регистрация поможет с более точным заполнением документа,
							а так же даст вам возможность отслеживать движение документа и
							получить доступ к нашим подпискам и акциям
						</p>

						<Button
							classes={s.btn}
							text={
								<>
									Зарегистрироваться
									<ArrowFlag />
								</>
							}
						/>
					</div>

					<div className={s.stepCard}>
						<img src={img} alt='img' />

						<h4>
							<span>3.</span> Заполнить анкету
						</h4>

						<p>
							Заполнение анкеты для документа очень важный процесс, от
							правильности заполнения анкеты зависит решения суда. Очень важно
							обдумать ответ на каждый вопрос
						</p>

						<Button
							classes={s.btn}
							text={
								<>
									Частые вопросы
									<ArrowFlag />
								</>
							}
						/>
					</div>
				</div>
			</Section>

			<Section className={s.section}>
				<h3 className={`title`}>Что делаем мы</h3>

				<div className={s.cardList}>
					{weDo.map((el, i) => (
						<WhiteCard key={el.title} classes={s.card}>
							<h4>
								<span>{++i}.</span> {el.title}
							</h4>

							<p>{el.description}</p>
						</WhiteCard>
					))}
				</div>
			</Section>

			<Section className={s.section}>
				<h3 className={`title`}>Дополнительный функционал</h3>

				<div className={s.cardList}>
					<WhiteCard classes={s.card}>
						<h4>Найти юриста</h4>

						<p>
							Не нашли нужный документ? Или нужно постоянное сопровождение? Вам
							поможет наш раздел Юристы, где собраны профессиональные юристы с
							рейтингом и отзывами
						</p>

						<Button
							classes={s.btn}
							text={
								<>
									Юристы <ArrowFlag />
								</>
							}
						/>
					</WhiteCard>

					<WhiteCard classes={s.card}>
						<h4>Определить подсудность</h4>

						<p>
							Не знаете какой нужен суд? Просто введите свой адрес, выберите
							категорию дела и мы поможем с выбором
						</p>

						<Button
							classes={s.btn}
							text={
								<>
									Подсудность <ArrowFlag />
								</>
							}
						/>
					</WhiteCard>
				</div>
			</Section>

			<Section className={s.section}>
				<h3 className={`title`}>Для юристов</h3>

				<div className={s.cardList}>
					<WhiteCard classes={s.card}>
						<h4>Размещение</h4>

						<p>
							Чтобы попасть в рейтинг юристов и получать заказы через нашу
							платформу, пройдите регистрацию
						</p>

						<Button
							classes={s.btn}
							text={
								<>
									Зарегистрироваться
									<ArrowFlag />
								</>
							}
						/>
					</WhiteCard>

					<WhiteCard classes={s.card}>
						<h4>Публикация</h4>

						<p>Размещайте свои документы на нашей платформе и зарабатывайте</p>

						<Button
							classes={s.btn}
							text={
								<>
									Подать заявку <ArrowFlag />
								</>
							}
						/>
					</WhiteCard>
				</div>
			</Section>
		</PageContainer>
	);
};
