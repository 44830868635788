import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import s from '../../authentication.module.scss';
import { ReactComponent as GosUslygiIcon } from '../../../../../img/loginIcons/gosUslygiIcon.svg';
import { ReactComponent as Arrow } from '../../../../../img/linkArrow.svg';
import licenseAgreement from '../../../../../documents/Лицензионное_соглашение.pdf';
import privacyPolicy from '../../../../../documents/Политика_конфиденциальности.pdf';
import offerSales from '../../../../../documents/Оферта_продажи_программных_продуктов.pdf';
import { Checkbox } from '../../../checkbox/checkbox';
import { FormCard } from '../../../formCard/formCard';
import { Input } from '../../../input/input';
import { InputPassword } from '../../../input/inputPassword';
import { Button } from '../../../button/button';
import {
	resetPassword,
	verifyEmail,
} from '../../../../../store/features/user/thunks';
import { ActionWindow } from '../../actionWindow/actionWindow';
import { InformationWindow } from '../../informationWindow/informationWindow';

export const LoginByEmail = memo(
	({
		submitFn,
		navigateClickFn,
		emailValue,
		changeEmailFn,
		passwordValue,
		changePasswordFn,
		isRemember,
		changeIsRememberFn,
		openRegisterFn,
		error = '',
		setError,
		dispatch,
	}) => {
		const EMAIL_LOGIN_TYPE = 'EMAIL_LOGIN_TYPE';
		const CONFIRM_EMAIL_TYPE = 'CONFIRM_EMAIL_TYPE';
		const CONFIRMATION_SENT_TYPE = 'CONFIRMATION_SENT_TYPE';
		const FORGOT_PASSWORD_TYPE = 'FORGOT_PASSWORD_TYPE';
		const NEW_PASSWORD_SENT_TYPE = 'CONFIRMATION_SENT_TYPE';

		const EMAIL_NOT_CONFIRMED_ERROR = 'Почта не подтверждена';

		const [windowType, setWindowType] = useState(EMAIL_LOGIN_TYPE);

		const [isAgreeOne, setIsAgreeOne] = useState(false);
		const [isAgreeTwo, setIsAgreeTwo] = useState(false);

		const handlerNavigate = useCallback(() => {
			setWindowType(EMAIL_LOGIN_TYPE);
			setError('');
		}, [EMAIL_LOGIN_TYPE]);

		const handlerForgotPassword = useCallback(() => {
			setWindowType(FORGOT_PASSWORD_TYPE);
		}, [FORGOT_PASSWORD_TYPE]);

		const textIsAgreeOne = useMemo(
			() => (
				<p>
					Я согласен на обработку персональных данных и соглашаюсь c{' '}
					<a href={privacyPolicy} target='_blank'>
						Политикой конфиденциальности
					</a>
				</p>
			),
			[]
		);
		const textIsAgreeTwo = useMemo(
			() => (
				<p>
					Я ознакомлен и соглашаюсь с условиями{' '}
					<a href={licenseAgreement} target='_blank'>
						Лицензионного соглашения
					</a>{' '}
					и{' '}
					<a href={offerSales} target='_blank'>
						Офертой продажи программных продуктов
					</a>
				</p>
			),
			[]
		);

		const handlerChangeIsAgreeOne = useCallback(
			() => setIsAgreeOne((prev) => !prev),
			[]
		);
		const handlerChangeIsAgreeTwo = useCallback(
			() => setIsAgreeTwo((prev) => !prev),
			[]
		);

		const sendConfirmEmail = useCallback(() => {
			dispatch(
				verifyEmail({
					email: emailValue,
					setError,
					openInformWindow: () => setWindowType(CONFIRMATION_SENT_TYPE),
				})
			);
		}, [emailValue, CONFIRMATION_SENT_TYPE]);

		const sendNewPassword = useCallback(() => {
			dispatch(
				resetPassword({
					email: emailValue,
					setError,
					openInformWindow: () => setWindowType(CONFIRMATION_SENT_TYPE),
				})
			);
		}, [emailValue, CONFIRMATION_SENT_TYPE]);

		useEffect(() => {
			if (error === EMAIL_NOT_CONFIRMED_ERROR) {
				setWindowType(CONFIRM_EMAIL_TYPE);
			}
		}, [error]);

		switch (windowType) {
			case CONFIRM_EMAIL_TYPE:
				return (
					<ActionWindow
						submitFn={sendConfirmEmail}
						title='Ваша электронная почта не подтверждена! Подтвердить e-mail?'
						navigateClickFn={handlerNavigate}
						inputType='email'
						inputValue={emailValue}
						inputChangeFn={changeEmailFn}
						inputPlaceholder='Почта'
						error={error}
						submitBtnText='Подтвердить'
					/>
				);

			case CONFIRMATION_SENT_TYPE:
				return (
					<InformationWindow
						message={
							'На указанный адрес электронной почты отправлено сообщение. Завершите подтверждение электронной почты.'
						}
					/>
				);

			case FORGOT_PASSWORD_TYPE:
				return (
					<ActionWindow
						submitFn={sendNewPassword}
						title='Отправить новый пароль на указанный адрес электронной почты?'
						navigateClickFn={handlerNavigate}
						inputType='email'
						inputValue={emailValue}
						inputChangeFn={changeEmailFn}
						inputPlaceholder='Почта'
						error={error}
						submitBtnText='Отправить'
					/>
				);

			case NEW_PASSWORD_SENT_TYPE:
				return (
					<InformationWindow
						message={
							'Новый пароль отправлен на указанный адрес электронной почты.'
						}
					/>
				);

			default:
				return (
					<FormCard classes={s.window} submitFunction={submitFn}>
						<div className={`${s.navigate}`} onClick={navigateClickFn}>
							Войти по телефону
							<Arrow />
						</div>

						<h4>Вход по e-mail</h4>

						<div className={error ? s.inputError : s.inputBlock}>
							<Input
								type={'email'}
								inputValue={emailValue}
								changeFunction={changeEmailFn}
								placeholder='Почта'
								required={true}
							/>

							<InputPassword
								inputValue={passwordValue}
								changeFunction={changePasswordFn}
								placeholder='Пароль'
								required={true}
							/>
						</div>

						<span className={s.error}>{error}</span>

						<div className={s.rememberBlock}>
							<Checkbox
								text={'Запомнить'}
								isChecked={isRemember}
								changeFunction={changeIsRememberFn}
							/>

							<div onClick={handlerForgotPassword}>Забыли пароль?</div>
						</div>

						<div className={s.entryBlock}>
							<Button
								type='submit'
								classes={s.btn}
								text={'Войти'}
								disabled={!(isAgreeOne === true && isAgreeTwo === true)}
							/>

							<div>
								<Checkbox
									text={textIsAgreeOne}
									isChecked={isAgreeOne}
									changeFunction={handlerChangeIsAgreeOne}
								/>

								<Checkbox
									text={textIsAgreeTwo}
									isChecked={isAgreeTwo}
									changeFunction={handlerChangeIsAgreeTwo}
								/>
							</div>
						</div>

						<div className={s.loginOptionsBlock}>
							<p>Другие способы входа</p>

							<div className={s.icons}>
								<GosUslygiIcon />
								<GosUslygiIcon />
								<GosUslygiIcon />
								<GosUslygiIcon />
							</div>
						</div>

						<p className={s.authTypeBlock}>
							Нет учетной записи?{' '}
							<span onClick={openRegisterFn}>Зарегистрироваться</span>
						</p>
					</FormCard>
				);
		}
	}
);
