import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	organizationName: '',
	address: '',
	inn: null,
	ogrn: null,
	kpp: null,
	directorFirstName: '',
	directorLastName: '',
	directorMiddleName: '',
};

export const organizationDataSlice = createSlice({
	name: 'organizationData',
	initialState,

	reducers: {
		setOrganizationData: (state, action) =>
			(state = action.payload ? action.payload : initialState),
	},

	extraReducers: {},
});

export const { setOrganizationData } = organizationDataSlice.actions;
export const organizationDataReducer = organizationDataSlice.reducer;
