import officePageClasses from '../officePage.module.scss';
import userInfoClasses from '../userOffice/userData/userInfo/userInfo.module.scss';
import { ReactComponent as EditIcon } from '../../../../img/editIcon.svg';

export const DataEditBlock = (props) => {
	const { title, children, isEditForm = false, openFormFn } = props;

	return (
		<div className={userInfoClasses.infoBlock}>
			<div className={userInfoClasses.titleBlock}>
				<h3 className={officePageClasses.title}>{title}</h3>

				{isEditForm && (
					<button className={userInfoClasses.editBtn} onClick={openFormFn}>
						<EditIcon />
					</button>
				)}
			</div>

			<div className={userInfoClasses.inputBlockList}>{children}</div>
		</div>
	);
};
