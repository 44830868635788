import s from './informationPage.module.scss';
import { Logo } from '../../common/logo/logo';
import { Navigation } from '../../common/navigation/navigation';
import { PageContainer } from '../../common/pageContainer/pageContainer';
import { Section } from '../../common/section/section';
import { UsefulBlock } from '../mainPage/usefulBlock/usefulBlock';
import { ReactComponent as Women } from '../../../img/woman.svg';
import { ReactComponent as IconPdf } from '../../../img/icon/pdf.svg';

export const InformationPage = () => {
	return (
		<PageContainer classes={s.container}>
			<Navigation
				linkList={[
					{
						text: 'Главная',
						path: '/',
					},
					{
						text: 'Информация',
					},
				]}
			/>

			<Section className={s.section}>
				<h3 className={`title`}>О нас</h3>

				<div className={s.logo}>
					<Women />
					<Logo />
				</div>

				<p>
					Мы — ваша надёжная опора в мире юриспруденции. Наша платформа
					предлагает удобный и простой способ для обычных людей подготовить
					необходимые документы онлайн, без необходимости обращаться к
					дорогостоящим юристам. С нашей помощью вы сможете самостоятельно
					заполнить нужные формы, распечатать их и успешно подать в суд, экономя
					время и деньги.
				</p>
			</Section>

			<Section className={s.usefulSection}>
				<UsefulBlock />
			</Section>

			<Section className={s.section}>
				<h3 className={`title`}>Правовая информация</h3>

				<div className={s.docList}>
					<div className={s.docList__item}>
						<img src={''} alt='doc' />

						<a href={'#'} target='_blank'>
							<IconPdf /> Лицензионное соглашение
						</a>
					</div>

					<div className={s.docList__item}>
						<img src={''} alt='doc' />

						<a href={'#'} target='_blank'>
							<IconPdf /> Лицензионное соглашение
						</a>
					</div>

					<div className={s.docList__item}>
						<img src={''} alt='doc' />

						<a href={'#'} target='_blank'>
							<IconPdf /> Лицензионное соглашение
						</a>
					</div>

					<div className={s.docList__item}>
						<img src={''} alt='doc' />

						<a href={'#'} target='_blank'>
							<IconPdf /> Лицензионное соглашение
						</a>
					</div>
				</div>
			</Section>
		</PageContainer>
	);
};
