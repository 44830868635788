import { useState } from 'react';
import s from './supportContent.module.css';
import { Button } from '../../../../common/button/button';
import { Input } from '../../../../common/input/input';
import { ReactComponent as ArrowFlag } from '../../../../../img/linkArrowFlag.svg';
import { ReactComponent as Paperclip } from '../../../../../img/paperclip.svg';
import { ReactComponent as SearchIcon } from '../../../../../img/searchIcon.svg';
import { InputFile } from '../../../../common/input/inputFile';

export const SupportContent = () => {
	const [file, setFile] = useState('Прикрепить файл');

	return (
		<div className={s.container}>
			<h3 className={s.title}>Поддержка</h3>

			<form className={s.form} onSubmit={() => {}}>
				<label>
					Тема вопроса
					<Input
						classes={s.input}
						// inputValue={}
						// changeFunction={}
						// clickFunction={}
						// keyDownEnterFunction={}
						placeholder={'Введите тему вопроса'}
					/>
				</label>

				<label>
					Текст вопроса
					<textarea
						rows={10}
						maxLength={400}
						placeholder='Задайте вопрос'></textarea>
				</label>

				<div>
					{/* <label className={s.file}>
						<Input
							type={'file'}
							changeFunction={(e) => setFile(e.target.files[0].name)}
						/>

						<Paperclip />

						{file}
					</label> */}
					<InputFile icon={<Paperclip />} />

					<Button
						type={'submit'}
						classes={s.btn}
						text={
							<>
								Отправить <ArrowFlag />
							</>
						}
					/>
				</div>
			</form>

			<div className={s.appealsBlock}>
				<div className={s.appealsHeadRow}>
					<div>
						<h3 className={s.title}>История оращений</h3>

						<div className={s.search}>
							<Input
								// classes={s.input}
								// inputValue={}
								// changeFunction={}
								// clickFunction={}
								// keyDownEnterFunction={}
								placeholder={'Поиск'}
							/>

							<SearchIcon className={s.searchIcon} />
						</div>
					</div>

					<p>Сортировать: по дате</p>
				</div>

				<Appeal />
			</div>
		</div>
	);
};

function Appeal() {
	return (
		<div className={s.appeal}>
			<div className={s.appealHead}>
				<p>Обращение: #24654 “Работа сервиса”</p>

				<p>08.02.2024</p>
			</div>

			<p>Заявление заполнено, но до сих пор не отправлено</p>

			<p>
				Ответ: Могут, на основании Федерального закона от 24.07.2023 N 385-ФЗ "О
				внесении изменений в Федеральный закон "Об образовании в Российской
				Федерации" и статью 4 Федерального закона "О науке и государственной
				научно-технической политике". Следует учесть, что порядок допуска таких
				лиц к работе поручено установить Минпросвещения России по согласованию с
				Минтрудом России. В настоящее время порядок еще не принят. Обоснование:
				Воспитатель является педагогическим работником (подраздел 2 разд. I
				Номенклатуры, утв. Постановлением Правительства РФ от 21.02.2022 N 225).
			</p>

			<div className={s.appealLastRow}>
				<p>Рассмотрен</p>

				<button>Ответить</button>
			</div>
		</div>
	);
}
