import axios from 'axios';

const instance = axios.create({
	baseURL: 'http://95.79.30.69:81',
});

const test = axios.create({
	baseURL: 'http://95.79.30.69:9191',
	credentials: true,
	'access-control-allow-credentials': true,
});

function createInstanceWithAuthToken(token) {
	const instance = axios.create({
		baseURL: 'http://95.79.30.69:81',
		credentials: true,
		'access-control-allow-credentials': true,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return instance;
}

export const organizationApi = {
	register(organizationData) {
		return instance.post('registerLegal ', organizationData);
	},

	getOrganization(userToken) {
		const instance = createInstanceWithAuthToken(userToken);
		return instance.get(`getLegalData`);
	},

	setOrganization(userToken, organizationData) {
		const instance = createInstanceWithAuthToken(userToken);
		return instance.post('updateLegalData', organizationData);
	},
};

export const userApi = {
	register(userData) {
		return instance.post('register', userData);
	},

	getUser(userToken) {
		const instance = createInstanceWithAuthToken(userToken);

		return instance.get('getUserData');
	},

	// up;dateData(userData) {
	// 	return instance.post('/user/edit', userData);
	// },

	resetPassword(email) {
		return instance.post('/user/resetPassword', { email });
	},

	getUserData(userToken) {
		// return instance.get(`/getUserPrivateData?userid=${userId}`);
		const instance = createInstanceWithAuthToken(userToken);

		return instance.get(`/getUserPrivateData`);
	},

	setPassportDetails(userToken, userData) {
		const instance = createInstanceWithAuthToken(userToken);

		return instance.post('updateUserPrivateData', userData);
	},

	setINN({ userToken, inn }) {
		const instance = createInstanceWithAuthToken(userToken);

		return instance.post('updateUserAdditionalData', { inn });
	},

	setSNILS({ userToken, snils }) {
		const instance = createInstanceWithAuthToken(userToken);

		return instance.post('updateUserAdditionalData', { snils });
	},

	setActualAddress({ userToken, factaddress }) {
		const instance = createInstanceWithAuthToken(userToken);

		return instance.post('updateUserAdditionalData', { factaddress });
	},

	setUserFullName({ userToken, firstName, lastName, middleName }) {
		const instance = createInstanceWithAuthToken(userToken);

		return instance.post('updateLegalUserName', {
			firstName,
			lastName,
			middleName,
		});
	},
};

export const authApi = {
	emailAuth(email, password) {
		return instance.post('emailAuth', { email, password });
	},

	getSMSCode(phone) {
		return instance.post('sendSms', { phone });
	},

	phoneAuth(phone, code) {
		return instance.post('phoneAuth', { phone, code });
	},
};

export const verifyApi = {
	isVerify(path) {
		return instance.get(`${path}`);
	},

	verifySms(phoneData) {
		return instance.post('verifySms', phoneData);
	},

	verifyEmail(email) {
		return instance.post('/resend-token', { email });
	},
};

export const userDocApi = {
	createUserDoc(userData) {
		return instance.post('create_document', userData);
	},

	getUserDocList(userId) {
		return instance.post('get_user_documents', userId);
	},

	getUserDoc(userData) {
		return instance.post('get_document', {
			signnumber: userData.href,
			update: userData.update,
		});
	},

	updateUserDoc(userData) {
		return instance.post('update_document', userData);
	},

	// getCourt(userRegion, courtType) {
	// 	// TEST!!!!!!
	// 	return test.get(
	// 		`get-court?region=${userRegion}`
	// 		// { region: userRegion, }
	// 	);
	// },

	// getRegionList() {
	// 	return test.get(`regions`);
	// },
};

export const templateApi = {
	getDirList() {
		return instance.get('folders');
	},

	getTemplateList() {
		return instance.get('document_templates');
	},

	saveTemplate(doc) {
		return instance.post('save_doc_template', doc);
	},

	createPDF(html) {
		return instance.post(
			'generateCustomPDF',
			{ html: html },
			{ responseType: 'blob' }
		);
	},
};

export const newsApi = {
	getNews() {
		return instance.get('get-articles');
	},
};
