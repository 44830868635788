import { useEffect } from 'react';
import { useNavigate, Route, Routes, useHref } from 'react-router-dom';
import { useAuth } from '../../../../core/hooks/useAuth';
import s from './userOffice.module.css';
import { MainMenu } from '../../../common/mainMenu/mainMenu';
import { Navigation } from '../../../common/navigation/navigation';
import { Section } from '../../../common/section/section';
import { MenuItem } from '../../../common/menuItem/menuItem';
import { ReactComponent as Man } from '../../../../img/icon/man.svg';
import { ReactComponent as MyPay } from '../../../../img/icon/myPay.svg';
import { ReactComponent as BookmarkIcon } from '../../../../img/bookmarkIcon.svg';
import { ReactComponent as Subscription } from '../../../../img/icon/subscription.svg';
import { ReactComponent as Settings } from '../../../../img/icon/setting.svg';
import { ReactComponent as Support } from '../../../../img/icon/support.svg';
import { UserData } from './userData/userData';
import { Favorites } from './favorites/favorites';
import { Subscriptions } from './subscriptions/subscriptions';
import { SettingsContent } from './settingsContent/settingsContent';
import { Purchases } from './purchases/purchases';
import { SupportContent } from './supportContent/supportContent';
import { PageContainer } from '../../../common/pageContainer/pageContainer';

export const UserOfficePage = () => {
	const navigate = useNavigate();
	const { isAuth } = useAuth();

	const href = useHref();

	const handlerNavigation = (path) => navigate(path);

	useEffect(() => {
		if (!isAuth) {
			navigate('/');
		}
	}, [isAuth]);

	// console.log(href);
	return (
		<PageContainer>
			<Navigation
				linkList={[
					{
						text: 'Главная',
						path: '/',
					},
					{
						text: 'Личный кабинет',
					},
				]}
			/>

			<Section className={s.section}>
				<MainMenu
					classes={s.categoryList}
					list={
						<>
							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Man />}
									name={'Личные данные'}
									clickFunction={() => handlerNavigation('userData')}
								/>

								<div
									style={
										href === '/userOffice/userData'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<MyPay />}
									name={'Мои покупки'}
									clickFunction={() => handlerNavigation('purchases')}
								/>

								<div
									style={
										href === '/userOffice/purchases'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<BookmarkIcon />}
									name={'Избранное'}
									clickFunction={() => handlerNavigation('favorites')}
								/>

								<div
									style={
										href === '/userOffice/favorites'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Subscription />}
									name={'Подписки'}
									clickFunction={() => handlerNavigation('subscriptions')}
								/>

								<div
									style={
										href === '/userOffice/subscriptions'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Settings />}
									name={'Настройки'}
									clickFunction={() => handlerNavigation('settings')}
								/>

								<div
									style={
										href === '/userOffice/settings'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Support />}
									name={'Поддержка'}
									clickFunction={() => handlerNavigation('support')}
								/>

								<div
									style={
										href === '/userOffice/support'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>
						</>
					}
				/>
			</Section>

			<div className={s.content}>
				<Routes>
					<Route path='userData' element={<UserData />} />
					<Route path='purchases' element={<Purchases />} />
					<Route path='favorites' element={<Favorites />} />
					<Route path='subscriptions' element={<Subscriptions />} />
					<Route path='settings' element={<SettingsContent />} />
					<Route path='support' element={<SupportContent />} />
				</Routes>
			</div>
		</PageContainer>
	);
};
