import { createAsyncThunk } from '@reduxjs/toolkit';
import { userApi } from '../../../../api/api';
import { imgConverterToBase64 } from '../../../../core/utils/functions/imgConverterToBase64/imgConverterToBase64';
import {
	setActualAddress,
	setINN,
	setPassportDetails,
	setSNILS,
} from '../userData/userDataSlice';

export const getUserData = createAsyncThunk(
	'user/userData/getUserData',
	async (userToken, { rejectWithValue, dispatch }) => {
		userApi
			.getUserData(userToken)
			.then((res) => {
				// console.log(res);
				const {
					passport,
					producer,
					claimdate,
					date,
					code,
					address,
					inn,
					snils,
					factaddress,
				} = res.data.data;

				const dateOfIssue = new Date(claimdate).toLocaleDateString();
				const dateOfBirth = new Date(date).toLocaleDateString();

				dispatch(
					setPassportDetails({
						series: passport.slice(0, 4),
						number: passport.slice(4),
						issued: producer,
						dateOfIssue,
						dateOfBirth,
						departmentCode: code,
						registrationAddress: address,
					})
				);
				dispatch(setINN(inn));
				dispatch(setSNILS(snils));
				dispatch(setActualAddress(factaddress));
			})
			.catch((err) => {
				console.log(err);
				// setError(err.response.data.error);
			});
	}
);

export const setUserPassportDetails = createAsyncThunk(
	'user/userData/setUserPassportDetails',
	async ({
		userToken,
		passportDetails,
		file,
		handlerError = () => {},
		openInformWindow = () => {},
	}) => {
		const {
			firstName,
			lastName,
			middleName,
			seriesAndNumber,
			issued,
			dateOfIssue,
			dateOfBirth,
			departmentCode,
			registrationAddress,
		} = passportDetails;

		handlerError('');

		let fileBase64 = null;

		try {
			fileBase64 = await imgConverterToBase64(file);
		} catch (err) {
			console.log(err);
		}

		userApi
			.setPassportDetails(userToken, {
				// userid: userId,
				firstname: firstName,
				lastname: lastName,
				middlename: middleName,
				passport: seriesAndNumber,
				producer: issued,
				claimdate: dateOfIssue,
				date: dateOfBirth,
				code: departmentCode,
				address: registrationAddress,
				photo: fileBase64,
			})
			.then((res) => {
				// console.log(res);
				if (res.status === 200 || res.status === 201) {
					openInformWindow();
				}
			})
			.catch((err) => {
				console.log(err);
				handlerError(err.response.data.error);
			});
	}
);

export const setUserINN = createAsyncThunk(
	'user/userData/setUserINN',
	async ({ userToken, inn, handlerError = () => {} }, { dispatch }) => {
		handlerError('');

		userApi
			.setINN({
				userToken,
				inn,
			})
			.then((res) => {
				// console.log(res);
				dispatch(setINN(res.data.user.inn));
			})
			.catch((err) => {
				console.log(err);
				handlerError(err.response.data.error);
			});
	}
);

export const setUserSNILS = createAsyncThunk(
	'user/userData/setUserSNILS',
	async ({ userToken, snils, handlerError = () => {} }, { dispatch }) => {
		handlerError('');

		userApi
			.setSNILS({
				userToken,
				snils,
			})
			.then((res) => {
				// console.log(res);
				dispatch(setSNILS(res.data.user.snils));
			})
			.catch((err) => {
				console.log(err);
				handlerError(err.response.data.error);
			});
	}
);

export const setUserActualAddress = createAsyncThunk(
	'user/userData/setUserActualAddress',
	async (
		{ userToken, actualAddress, handlerError = () => {} },
		{ dispatch }
	) => {
		handlerError('');

		userApi
			.setActualAddress({
				userToken,
				factaddress: actualAddress,
			})
			.then((res) => {
				// console.log(res);
				dispatch(setActualAddress(res.data.user.factaddress));
			})
			.catch((err) => {
				console.log(err);
				handlerError(err.response.data.error);
			});
	}
);
