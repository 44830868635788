import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import s from '../../userInfo.module.scss';
import { Button } from '../../../../../../../common/button/button';
import { FormCard } from '../../../../../../../common/formCard/formCard';
import { Input } from '../../../../../../../common/input/input';
import { InputFile } from '../../../../../../../common/input/inputFile';
import { ReactComponent as Paperclip } from '../../../../../../../../img/paperclip.svg';
import { InputDate } from '../../../../../../../common/input/inputDate';
import { setUserPassportDetails } from '../../../../../../../../store/features/user/userData/thunks';
import { formatDepartmentCodeInPassport } from '../../../../../../../../core/utils/functions/inputValidation/formatDepartmentCodeInPassport/formatDepartmentCodeInPassport';

export const PassportForm = memo(
	({ userToken, openInformWindow, error, handlerError }) => {
		const dispatch = useDispatch();

		const [firstName, setFirstName] = useState('');
		const [lastName, setLastName] = useState('');
		const [middleName, setMiddleName] = useState('');
		const [seriesAndNumber, setSeriesAndNumber] = useState('');
		const [issued, setIssued] = useState('');
		const [dateOfIssue, setDateOfIssue] = useState('');
		const [dateOfBirth, setDateOfBirth] = useState('');
		const [departmentCode, setDepartmentCode] = useState('');
		const [registrationAddress, setRegistrationAddress] = useState('');
		const [file, setFile] = useState('');

		const handlerLastName = useCallback((e) => setLastName(e.target.value), []);

		const handlerFirstName = useCallback(
			(e) => setFirstName(e.target.value),
			[]
		);

		const handlerMiddleName = useCallback(
			(e) => setMiddleName(e.target.value),
			[]
		);

		const handlerSeriesAndNumber = useCallback((e) => {
			if (e.target.value.length <= 10) {
				setSeriesAndNumber(e.target.value);
			}
		}, []);

		const handlerIssued = useCallback(
			(e) => setIssued(e.target.value.toUpperCase()),
			[]
		);

		const handlerDateOfIssued = useCallback(
			(e) => setDateOfIssue(e.target.value),
			[]
		);
		const handlerDateOfBirth = useCallback(
			(e) => setDateOfBirth(e.target.value),
			[]
		);

		const handlerDepartmentCode = useCallback((e) => {
			if (e.target.value.length <= 7) {
				setDepartmentCode(formatDepartmentCodeInPassport(e.target.value));
			}
		}, []);

		const handlerRegistrationAddress = useCallback(
			(e) => setRegistrationAddress(e.target.value),
			[]
		);

		const handlerFile = useCallback((e) => {
			setFile(e.target.files[0]);
		}, []);

		const handlerPassportData = useCallback(() => {
			dispatch(
				setUserPassportDetails({
					userToken,
					passportDetails: {
						firstName,
						lastName,
						middleName,
						seriesAndNumber,
						issued,
						dateOfIssue,
						dateOfBirth,
						departmentCode,
						registrationAddress,
					},
					file,
					handlerError,
					openInformWindow,
				})
			);
		}, [
			userToken,
			firstName,
			lastName,
			middleName,
			seriesAndNumber,
			issued,
			dateOfIssue,
			dateOfBirth,
			departmentCode,
			registrationAddress,
			file,
		]);

		const svgMemo = useMemo(() => <Paperclip />, []);

		return (
			<FormCard
				title={'Паспортные данные'}
				classes={s.form}
				submitFunction={handlerPassportData}>
				<div className={s.form__inputList}>
					<Input
						classes={s.input}
						inputValue={lastName}
						changeFunction={handlerLastName}
						placeholder='Фамилия*'
						required={true}
					/>

					<Input
						classes={s.input}
						inputValue={firstName}
						changeFunction={handlerFirstName}
						placeholder='Имя*'
						required={true}
					/>
					<Input
						classes={s.input}
						inputValue={middleName}
						changeFunction={handlerMiddleName}
						placeholder='Отчество'
						required={false}
					/>
					<Input
						classes={s.input}
						type={'number'}
						inputValue={seriesAndNumber}
						changeFunction={handlerSeriesAndNumber}
						placeholder='Серия, номер паспорта*'
						required={true}
					/>
					<Input
						classes={s.input}
						inputValue={issued}
						changeFunction={handlerIssued}
						placeholder='Кем выдан*'
						required={true}
					/>

					<InputDate
						classes={s.input}
						inputValue={dateOfIssue}
						changeFunction={handlerDateOfIssued}
						placeholder='Дата выдачи*'
						required={true}
					/>

					<Input
						classes={s.input}
						// type={'number'}
						inputValue={departmentCode}
						changeFunction={handlerDepartmentCode}
						placeholder='Код подразделения*'
						required={true}
					/>

					<InputDate
						classes={s.input}
						inputValue={dateOfBirth}
						changeFunction={handlerDateOfBirth}
						placeholder='Дата рождения*'
						required={true}
					/>

					<Input
						classes={s.input}
						inputValue={registrationAddress}
						changeFunction={handlerRegistrationAddress}
						placeholder='Адрес регистрации*'
						required={true}
					/>
				</div>

				<div className={s.file}>
					<InputFile
						file={file}
						changeFunction={handlerFile}
						icon={svgMemo}
						text={'Прикрепить фотографию'}
						fileType={'image/png, image/jpeg, image/jpg'}
						required={true}
					/>

					<p>Для замены данных необходимо приложить фотографию паспорт</p>
				</div>

				{error && <div className={s.error}>{error}</div>}
				<Button type='submit' classes={s.btn} text={'Сохранить'} />
			</FormCard>
		);
	}
);
