import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from '../../../../../../core/hooks/useToggle';
import { selectUser } from '../../../../../../store/selectors';
import { OrganizationInfo } from './organizationInfo/organizationInfo';
import { IndividualInfo } from './individualInfo/individualInfo';

export const UserInfo = () => {
	const user = useSelector(selectUser);

	const [isOpenForm, setIsOpenForm] = useToggle(false);
	const [isWindowWithForm, toggle] = useToggle(true);

	const [error, setError] = useState('');

	const handlerError = useCallback((err) => {
		setError(err);
	}, []);

	switch (user.userType) {
		case 3:
			return (
				<OrganizationInfo
					user={user}
					isOpenForm={isOpenForm}
					setIsOpenForm={setIsOpenForm}
					isWindowWithForm={isWindowWithForm}
					openInformWindow={toggle}
					error={error}
					handlerError={handlerError}
				/>
			);
		// case 1:
		default:
			return (
				<IndividualInfo
					user={user}
					isOpenForm={isOpenForm}
					setIsOpenForm={setIsOpenForm}
					isWindowWithForm={isWindowWithForm}
					openInformWindow={toggle}
					error={error}
					handlerError={handlerError}
				/>
			);
	}
};
