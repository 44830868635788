import { memo } from 'react';
import s from '../menuCard.module.scss';
import { ReactComponent as Exit } from '../../../../../../img/exit.svg';

export const AdminMenuCard = memo(
	({
		navigateModeration,
		navigateLibrary,
		navigateUsers,
		navigateSupport,
		handlerExit,
	}) => {
		return (
			<ul className={s.menu}>
				<li className={s.item} onClick={navigateModeration}>
					<a href='#' className={s.link}>
						Модерация
					</a>
				</li>
				<li className={s.item} onClick={navigateLibrary}>
					<a href='#' className={s.link}>
						Библиотека
					</a>
				</li>
				<li className={s.item} onClick={navigateUsers}>
					<a href='#' className={s.link}>
						Пользователи
					</a>
				</li>
				<li className={s.item} onClick={navigateSupport}>
					<a href='#' className={s.link}>
						Поддержка
					</a>
				</li>
				<li className={s.item} onClick={handlerExit}>
					<a href='#' className={s.link}>
						<Exit className={s.icon} />
						Выйти
					</a>
				</li>
			</ul>
		);
	}
);
