import s from './whyUs.module.scss';
import { WhyUsCard } from './whyUsCard/whyUsCard';
import { ReactComponent as Time } from '../../../../img/icon/time.svg';
import { ReactComponent as Read } from '../../../../img/icon/read.svg';
import { ReactComponent as World } from '../../../../img/icon/world.svg';
import { ReactComponent as Peoples } from '../../../../img/icon/peoples.svg';
import { ReactComponent as Shield } from '../../../../img/icon/shield.svg';
import { ReactComponent as CallCenter } from '../../../../img/icon/callCenter.svg';
import bgrFlag from '../../../../img/bgrFlag.png';

export const WhyUs = () => {
	return (
		<div className={s.container}>
			<h3 className={`title`}>Почему мы</h3>

			<div className={s.cards}>
				<WhyUsCard
					icon={<Time className={s.icon} />}
					title={'Экономия времени'}
					text={
						'Онлайн услуга занимает гораздо меньше времени, чем посещение государственных органов или юриста лично.'
					}
				/>

				<WhyUsCard
					icon={<Read className={s.icon} />}
					title={'Широкий спектр услуг'}
					text={
						'У нас можно получить не только готовый документ и отправить его в суд, но и получить консультанцию юриста.'
					}
				/>

				<WhyUsCard
					icon={<World />}
					title={'Удобство'}
					text={
						'Все операции можно выполнить из дома, офиса или даже в дороге, без необходимости куда-то ехать.'
					}
				/>

				<WhyUsCard
					icon={<Peoples />}
					title={'Профессионализм'}
					text={
						'Команда опытных разработчиков, юристов и прочих специалистов, которые помогут вам в любых вопросах.'
					}
				/>

				<WhyUsCard
					icon={<Shield />}
					title={'Безопасность'}
					text={
						'Все данные передаются по защищенному каналу, что гарантирует их сохранность и конфиденциальность.'
					}
				/>

				<WhyUsCard
					icon={<CallCenter className={s.icon} />}
					title={'Круглосуточная поддержка'}
					text={
						'Наша команда готова ответить на ваши вопросы и помочь в решении возникающих проблем в любое время.'
					}
				/>
			</div>

			<img src={bgrFlag} alt='flag' />
		</div>
	);
};
