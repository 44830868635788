import s from './userDataFieldBlock.module.scss';

export const UserDataFieldBlock = ({ title, data }) => {
	return (
		<div className={s.container}>
			<p>{title}:</p>
			<p className={`${s.data} ${!data && s.data_null}`}>
				{data ? data : 'Не заполнено'}
			</p>
		</div>
	);
};
