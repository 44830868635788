import { useEffect } from 'react';

export const useOutsideClick = (ref, handlerClose, attached = true) => {
	const handlerOutsideClick = (e) => {
		if (!ref.current) return;

		if (!ref.current.contains(e.target)) {
			handlerClose();
		}
	};

	useEffect(() => {
		if (!attached) return;
		document.addEventListener('mousedown', handlerOutsideClick);

		return () => document.removeEventListener('mousedown', handlerOutsideClick);
	}, [ref, handlerClose, attached]);
};
