import { memo } from 'react';
import s from './headerBtn.module.css';

export const HeaderBtn = memo(({ icon, text, classes, clickFunction }) => {
	return (
		<div className={`${s.btn} ${classes}`} onClick={clickFunction}>
			{icon}

			<p>{text}</p>
		</div>
	);
});
