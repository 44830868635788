import { memo } from 'react';
import { useToggle } from '../../../../core/hooks/useToggle';
import s from './userMenu.module.css';
import { ReactComponent as UserIcon } from '../../../../img/userIcon.svg';
import { MenuCard } from './menuCard/menuCard';

export const UserMenu = memo(({ userType, firstName }) => {
	const [menuOpen, setMenuOpen] = useToggle(false);

	// console.log(userData);
	return (
		<div className={s.container}>
			<div className={s.userBlock} onClick={() => setMenuOpen(!menuOpen)}>
				<UserIcon className={s.icon} />

				<span className={menuOpen ? `${s.active}` : ''}>{firstName}</span>
			</div>

			{menuOpen ? (
				<MenuCard userType={userType} setMenuOpen={setMenuOpen} />
			) : (
				<></>
			)}
		</div>
	);
});
