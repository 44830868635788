import { createAsyncThunk } from '@reduxjs/toolkit';
import { organizationApi } from '../../../../api/api';
import { setUser } from '../userSlice';
import { setOrganizationData } from './organizationDataSlice';

export const getOrganization = createAsyncThunk(
	'user/getOrganization',
	async (userToken, { rejectWithValue, dispatch }) => {
		organizationApi
			.getOrganization(userToken)
			.then((res) => {
				// console.log(res);
				const {
					userId,
					legalName,
					address,
					inn,
					ogrn,
					kpp,
					directorFirstName,
					directorLastName,
					directorMiddleName,
				} = res.data;

				dispatch(
					setOrganizationData({
						organizationName: legalName,
						address,
						inn,
						ogrn,
						kpp,
						directorFirstName:
							directorFirstName === null ? '' : directorFirstName,
						directorLastName: directorLastName === null ? '' : directorLastName,
						directorMiddleName:
							directorMiddleName === null ? '' : directorMiddleName,
					})
				);
			})
			.catch((err) => {
				console.log(err);
				// setError(err.response.data.error);
			});
	}
);

export const setOrganization = createAsyncThunk(
	'user/organizationData/setOrganizationData',
	async (
		{ userToken, organizationData, handlerError = () => {}, openInformWindow },
		{ dispatch }
	) => {
		const {
			organizationName,
			address,
			inn,
			ogrn,
			kpp,
			directorFirstName,
			directorLastName,
			directorMiddleName,
		} = organizationData;

		handlerError('');

		organizationApi
			.setOrganization(userToken, organizationData)
			.then((res) => {
				openInformWindow();
				dispatch(
					setOrganizationData({
						organizationName,
						legalAddress: address,
						inn,
						ogrn,
						kpp,
						directorFirstName,
						directorLastName,
						directorMiddleName,
					})
				);
			})
			.catch((err) => {
				console.log(err);
				handlerError(err.response.data.error);
			});
	}
);
