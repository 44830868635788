import s from './library.module.css';
import { Input } from '../../../../common/input/input';
import { Button } from '../../../../common/button/button';
import { ReactComponent as SearchIcon } from '../../../../../img/searchIcon.svg';
import { ReactComponent as Law } from '../../../../../img/icon/law.svg';
import { ReactComponent as Home } from '../../../../../img/icon/home.svg';
import { ReactComponent as Car } from '../../../../../img/icon/car.svg';
import { ReactComponent as Family } from '../../../../../img/icon/family.svg';
import { ReactComponent as Money } from '../../../../../img/icon/money.svg';
import { ReactComponent as Work } from '../../../../../img/icon/work.svg';
import { CircleWithShadow } from '../../../../common/circleWithShadow/circleWithShadow';
import { DropdownMenu } from '../../../../common/dropdownMenu/dropdownMenu';
import { ReactComponent as Plus } from '../../../../../img/plus.svg';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export const Library = () => {
	const navigate = useNavigate();

	const navigateEditorPage = useCallback(() => navigate('/editorPage'), []);

	return (
		<div className={s.container}>
			<div className={s.headRow}>
				<div>
					<h3 className={s.title}>Библиотека</h3>

					<div className={s.search}>
						<Input
							classes={s.input}
							// inputValue={}
							// changeFunction={}
							// clickFunction={}
							// keyDownEnterFunction={}
							placeholder={'Поиск'}
						/>

						<SearchIcon className={s.searchIcon} />
					</div>
				</div>

				<div>
					<Button
						clickFunction={navigateEditorPage}
						text={
							<>
								<Plus /> Создать документ
							</>
						}
					/>

					<p>Сортировать: по дате</p>

					<p>Вся история</p>
				</div>
			</div>

			<div className={s.content}>
				<Card icon={<Law />} name={'Ущерб'} />
				<Card icon={<Home />} name={'Недвижимость и имущество'} />
				<Card icon={<Car />} name={'Транспорт'} />
				<Card icon={<Family />} name={'Семья и дети'} />
				<Card icon={<Money />} name={'Пособия и выплаты'} />
				<Card icon={<Work />} name={'Работа'} />

				<Card icon={'+'} name={'Добавить категорию'} />
			</div>
		</div>
	);
};

function Card({ icon, name }) {
	return (
		<div className={s.card}>
			<CircleWithShadow icon={icon} />
			<p>{name}</p>

			<DropdownMenu
				classes={s.menu}
				list={[
					{
						name: 'Переименовать',
						// clickFunction: () => handlerNavigate(id),
					},
					{ name: 'Удалить' },
				]}
			/>
		</div>
	);
}
