import { useState } from 'react';
import s from './headerMobile.module.css';
import { useDispatch } from 'react-redux';
import { FlagLine } from '../../flagLine/flagLine';
import { Logo } from '../../logo/logo';
import { BurgerMenuMobile } from '../burgerMenu/burgerMenuMobile/burgerMenuMobile';
import { ReactComponent as Grid } from '../../../../img/icon/grid.svg';
import { ReactComponent as Hammer } from '../../../../img/icon/hammer.svg';
import { ReactComponent as Bank } from '../../../../img/icon/bank.svg';
import { ReactComponent as Law } from '../../../../img/icon/law.svg';
import { ReactComponent as Info } from '../../../../img/icon/info.svg';
import { ReactComponent as Exit } from '../../../../img/exit.svg';
import { ReactComponent as Phone } from '../../../../img/icon/socNetworks/phone.svg';
import { ReactComponent as Telegram } from '../../../../img/icon/socNetworks/telegram.svg';
import { ReactComponent as WhatsApp } from '../../../../img/icon/socNetworks/whatsApp.svg';
import { ReactComponent as Mail } from '../../../../img/icon/socNetworks/mail.svg';
import { HeaderBtn } from '../headerBtn/headerBtn';
import { setUser } from '../../../../store/features/user/userSlice';
import { setStateDocument } from '../../../../store/features/document/documentSlice';
import { deleteCookie } from '../../../../core/utils/functions/cookie/deleteCookie';
import { useAuth } from '../../../../core/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export const HeaderMobile = ({ headerRef }) => {
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	const navigate = useNavigate();

	const [openMenu, setOpenMenu] = useState(false);

	const handlerSetOpenMenu = useCallback(
		() => setOpenMenu(!openMenu),
		[openMenu]
	);

	const navigateOnHowDoesThisWork = useCallback(
		() => handlerNavigate('howDoesThisWork'),
		[]
	);

	const navigateToInformation = useCallback(
		() => handlerNavigate('information'),
		[]
	);

	const handlerExit = useCallback(() => {
		deleteCookie('userAuth');
		deleteCookie('userType');

		dispatch(setUser());
		dispatch(setStateDocument());
		setOpenMenu(false);
	}, []);

	function handlerNavigate(path) {
		navigate(path);
		setOpenMenu(false);
	}

	return (
		<header id='headerMobile' ref={headerRef} className={s.headerMobile}>
			<div className={s.container}>
				<Logo classes={s.logo} />

				<BurgerMenuMobile
					clickFunction={handlerSetOpenMenu}
					isOpen={openMenu}
				/>
			</div>
			<FlagLine />

			<div
				style={
					openMenu
						? {
								width: '70%',
						  }
						: {}
				}
				className={s.menu}>
				<HeaderBtn
					classes={s.item}
					icon={<Grid />}
					text={'Как это работает?'}
					clickFunction={navigateOnHowDoesThisWork}
				/>
				<HeaderBtn classes={s.item} icon={<Hammer />} text={'Подать в суд'} />
				<HeaderBtn classes={s.item} icon={<Bank />} text={'Подсудность'} />
				<HeaderBtn classes={s.item} icon={<Law />} text={'Юристы'} />
				<HeaderBtn
					classes={s.item}
					icon={<Info />}
					text={'Информация'}
					clickFunction={navigateToInformation}
				/>
				{isAuth && (
					<HeaderBtn
						classes={s.item}
						icon={<Exit />}
						text={'Выйти'}
						clickFunction={handlerExit}
					/>
				)}

				<div className={s.socialNetwork}>
					<Phone />
					<Telegram />
					<WhatsApp />
					<Mail />
				</div>
			</div>
		</header>
	);
};
