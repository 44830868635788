import { useCallback, useEffect, useState } from 'react';
import { ModalWindow } from '../modalWindow/modalWindow';
import { Login } from './login/login';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/selectors';
import { useNavigate } from 'react-router-dom';
import { Register } from './register/register';

export const Authentication = ({ closeWindow }) => {
	const { userType } = useSelector(selectUser);
	const navigate = useNavigate();

	const LOGIN_TYPE = 'LOGIN_TYPE';
	const REGISTER_TYPE = 'REGISTER_TYPE';

	const [authType, setAuthType] = useState(LOGIN_TYPE);
	const [error, setError] = useState('');

	const openRegister = useCallback(() => {
		setAuthType(REGISTER_TYPE);
		setError('');
	}, [REGISTER_TYPE]);

	useEffect(() => {
		if (userType !== null) {
			switch (userType) {
				case '1': // admin
				case '2': // moderator
					navigate('/adminOffice/moderation');
					break;

				default: // "0","3" user
					break;
			}

			closeWindow();
		}
	}, [userType]);

	return (
		<ModalWindow clickFunction={closeWindow}>
			{authType === LOGIN_TYPE && (
				<Login openRegister={openRegister} error={error} setError={setError} />
			)}

			{authType === REGISTER_TYPE && (
				<Register
					setAuthType={setAuthType}
					LOGIN_TYPE={LOGIN_TYPE}
					error={error}
					setError={setError}
				/>
			)}
		</ModalWindow>
	);
};
