import s from './servicePage.module.scss';
import { Navigation } from '../../common/navigation/navigation';
import { Section } from '../../common/section/section';
import { Button } from '../../common/button/button';
import { Logo } from '../../common/logo/logo';
import img from '../../../img/image.png';
import { ReactComponent as Arrow } from '../../../img/linkArrowFlag.svg';
import { ReactComponent as Doc } from '../../../img/icon/doc.svg';
import { ReactComponent as Card } from '../../../img/icon/payCard.svg';
import { ReactComponent as Box } from '../../../img/icon/box.svg';
import { ReactComponent as DocDone } from '../../../img/icon/docDone.svg';

import { WhyUs } from '../mainPage/whyUs/whyUs';
import { CircleWithShadow } from '../../common/circleWithShadow/circleWithShadow';
import { PageContainer } from '../../common/pageContainer/pageContainer';

export const ServicePage = () => {
	return (
		<PageContainer classes={s.container}>
			<div className={s.navContainer}>
				<Navigation
					linkList={[
						{
							text: 'Главная',
							path: '/',
						},
						{
							text: 'Категории',
							path: -1,
						},
						{ text: 'serviceName' },
					]}
				/>

				<p>Добавить в избранное</p>
			</div>

			<Section className={s.headBlock}>
				<img src={img} alt='img' />

				<div>
					<div className={s.nameBlock}>
						<h5>Товары и услуги</h5>

						<h4>Претензия на возврат денежных средств</h4>
					</div>

					<div className={s.purchaseBlock}>
						<p>Стоимость</p>
						<p>1280 рублей</p>
						<Button
							text={
								<>
									Купить <Arrow />
								</>
							}
						/>
					</div>

					<div className={s.authorBlock}>
						<div>
							Автор: <Logo />
						</div>

						<div>
							<span>
								Дата публикации: <time dateTime='2024-01-19'>19.01.2024</time>
							</span>
							<span>
								Дата изменения: <time>19.01.2024</time>
							</span>
						</div>
					</div>
				</div>
			</Section>

			<Section className={s.descriptionBlock}>
				<h3 className={`title`}>Описание</h3>

				<p>
					Претензия на возврат денежных средств является официальным документом,
					который используется в ситуациях, когда покупатель хочет вернуть
					деньги за некачественный товар или услугу, которые были получены от
					продавца. Этот документ позволяет покупателю официально выразить свое
					недовольство и потребовать возврата денег, а также может быть
					использован в суде, если продавец откажется выполнять требования
					покупателя.
				</p>

				<div className={s.info}>
					<p>
						В претензии на возврат денежных средств обычно указывается следующая
						информация:
					</p>

					<ol>
						<li>
							Контактная информация покупателя (имя, адрес, телефон, электронная
							почта).
						</li>
						<li>
							Контактная информация продавца (название компании, адрес,
							телефон).
						</li>
						<li>
							Описание товара или услуги, за которую были уплачены деньги, а
							также дата и место покупки.
						</li>
						<li>
							Причина возврата денежных средств (некачественный товар, услуга,
							несоответствие описанию, повреждение при доставке, и т.д.).
						</li>
						<li>
							Требования покупателя, такие как возврат денег, замена товара,
							возмещение ущерба и т.д.
						</li>
						<li>
							Срок, в течение которого продавец должен выполнить требования
							покупателя. Обычно это 10-14 дней, но может варьироваться в
							зависимости от законодательства страны.
						</li>
						<li>
							Указание на то, что в случае невыполнения требований покупателя,
							он будет вынужден обратиться в суд.
						</li>
						<li>Подпись покупателя и дата составления претензии.</li>
					</ol>
				</div>
			</Section>

			<Section className={s.stagesBlock}>
				<h3 className={`title`}>Этапы подачи</h3>

				<div>
					<Item
						svg={<Doc />}
						title={'Заполнить анкету'}
						text={'Нажав кнопку “Воспользоваться”'}
					/>
					<Item
						svg={<Card />}
						title={'Произвести оплату'}
						text={'Онлайн оплата или воспользоваться подпиской'}
					/>
					<Item
						svg={<Box />}
						title={'Отправить в суд'}
						text={'самостоятельно или с помощью нашего сервиса'}
					/>
					<Item
						svg={<DocDone />}
						title={'Дождаться решения суда'}
						text={
							'Результат зависит от того на сколько внимательно вы заполнили анкету'
						}
					/>
				</div>
			</Section>

			<Section className={s.whyUsBlock}>
				<WhyUs />
			</Section>
		</PageContainer>
	);
};

function Item({ svg, title, text }) {
	return (
		<div className={s.item}>
			<CircleWithShadow icon={svg} />

			<div>
				<h5>{title}</h5>
				<p>{text}</p>
			</div>
		</div>
	);
}
