import { memo } from 'react';
import s from '../menuCard.module.scss';
import { ReactComponent as Exit } from '../../../../../../img/exit.svg';

export const UserMenuCard = memo(
	({ navigateUserData, navigatePurchases, navigateSupport, handlerExit }) => {
		return (
			<ul className={s.menu}>
				<li className={s.item} onClick={navigateUserData}>
					<a href='#' className={s.link}>
						Личные данные
					</a>
				</li>
				<li className={s.item} onClick={navigatePurchases}>
					<a href='#' className={s.link}>
						Покупки
					</a>
				</li>
				<li className={s.item}>
					<a href='#' className={s.link}>
						Конфиденциальность
					</a>
				</li>
				<li className={s.item} onClick={navigateSupport}>
					<a href='#' className={s.link}>
						Поддержка
					</a>
				</li>
				<li className={s.item} onClick={handlerExit}>
					<a href='#' className={s.link}>
						<Exit className={s.icon} />
						Выйти
					</a>
				</li>
			</ul>
		);
	}
);
