import { memo, useCallback, useEffect, useState } from 'react';
import s from '../userInfo.module.scss';
import { DataEditBlock } from '../../../../dataEditBlock/dataEditBlock';
import { InputEditBlock } from '../../../../inputEditBlock/inputEditBlock';
import { UserDataFieldBlock } from '../../../../userDataFieldBlock/userDataFieldBlock';
import { ModalWindow } from '../../../../../../common/modalWindow/modalWindow';
import { PassportForm } from './passportForm/passportForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from '../../../../../../../store/selectors';
import { InformationWindow } from '../../../../../../common/authentication/informationWindow/informationWindow';
import {
	setUserActualAddress,
	setUserINN,
	setUserSNILS,
} from '../../../../../../../store/features/user/userData/thunks';
import { getUserToken } from '../../../../../../../core/utils/functions/user/getUserToken';

export const IndividualInfo = memo(
	({
		user,
		isOpenForm,
		setIsOpenForm,
		isWindowWithForm,
		openInformWindow,
		error,
		handlerError,
	}) => {
		const dispatch = useDispatch();

		const [userToken, setUserToken] = useState(null);

		const { email, phone, firstName, lastName, middleName } = user;
		const { passportDetails, inn, snils, actualAddress } =
			useSelector(selectUserData);
		const {
			series,
			number,
			issued,
			dateOfIssue,
			dateOfBirth,
			departmentCode,
			registrationAddress,
		} = passportDetails;

		const [userFullName, setUserFullName] = useState(
			`${lastName} ${firstName} ${middleName}`
		);

		const saveINN = useCallback(
			(value) => {
				if (value === inn || value === '') return;

				dispatch(
					setUserINN({
						userToken,
						inn: value,
						handlerError,
					})
				);
			},
			[userToken, inn]
		);

		const saveSNILS = useCallback(
			(value) => {
				if (value === snils || value === '') return;

				dispatch(
					setUserSNILS({
						userToken,
						snils: value,
						handlerError,
					})
				);
			},
			[userToken, snils]
		);

		const saveActualAddress = useCallback(
			(value) => {
				if (value === actualAddress || value === '') return;

				dispatch(
					setUserActualAddress({
						userToken,
						actualAddress: value,
						handlerError,
					})
				);
			},
			[userToken, actualAddress]
		);

		useEffect(() => {
			setUserToken(getUserToken());
		}, []);

		useEffect(() => {
			if (firstName && lastName) {
				middleName
					? setUserFullName(`${lastName} ${firstName} ${middleName}`)
					: setUserFullName(`${lastName} ${firstName}`);
			}
		}, [firstName, lastName, middleName]);

		return (
			<div className={s.userInfo}>
				<div>
					<DataEditBlock title={'Контактная информация'}>
						<InputEditBlock
							title={'Почта'}
							inputType={'email'}
							inputValue={email}
							placeholder={'Введите электронный адрес'}
						/>
						<InputEditBlock
							title={'Номер телефона'}
							inputType={'phone'}
							inputValue={phone}
							placeholder={'Введите номер'}
						/>
					</DataEditBlock>

					<DataEditBlock title={'Дополнительная информация'}>
						<InputEditBlock
							title={'ИНН'}
							inputType={'number'}
							inputValue={inn}
							submitFunction={saveINN}
							placeholder={'Введите ИНН'}
							maxLength={12}
						/>
						<InputEditBlock
							title={'СНИЛС'}
							inputType={'number'}
							inputValue={snils}
							submitFunction={saveSNILS}
							placeholder={'Введите СНИЛС'}
							maxLength={11}
						/>
						<InputEditBlock
							title={'Фактический адрес'}
							inputValue={actualAddress}
							submitFunction={saveActualAddress}
							placeholder={'Введите фактический адрес проживания'}
						/>
					</DataEditBlock>
				</div>

				<DataEditBlock
					title={'Паспортные данные'}
					isEditForm={true}
					openFormFn={setIsOpenForm}>
					<UserDataFieldBlock title={'ФИО'} data={userFullName} />
					<UserDataFieldBlock
						title={'Серия, номер'}
						data={series && number && `${series} ${number}`}
					/>
					<UserDataFieldBlock title={'Кем выдан'} data={issued} />
					<UserDataFieldBlock title={'Дата выдачи'} data={dateOfIssue} />
					<UserDataFieldBlock
						title={'Код подразделения'}
						data={departmentCode}
					/>
					<UserDataFieldBlock title={'Дата рождения'} data={dateOfBirth} />
					<UserDataFieldBlock
						title={'Адрес регистрации'}
						data={registrationAddress}
					/>
				</DataEditBlock>

				{isOpenForm && (
					<ModalWindow clickFunction={setIsOpenForm}>
						{isWindowWithForm ? (
							<PassportForm
								userToken={userToken}
								openInformWindow={openInformWindow}
								error={error}
								handlerError={handlerError}
							/>
						) : (
							<InformationWindow message={'Данные успешно отправленны.'} />
						)}
					</ModalWindow>
				)}
			</div>
		);
	}
);
