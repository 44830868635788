import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import s from './header.module.css';
import { useAuth } from '../../../core/hooks/useAuth';

import { FlagLine } from '../flagLine/flagLine';
import { useHref, useNavigate } from 'react-router-dom';
import { BurgerMenu } from './burgerMenu/burgerMenu';
import { ReactComponent as SearchIcon } from '../../../img/searchIcon.svg';
import { ReactComponent as BookmarkIcon } from '../../../img/bookmarkIcon.svg';
import { ReactComponent as BellIcon } from '../../../img/bellIcon.svg';
import { ReactComponent as UserIcon } from '../../../img/userIcon.svg';
import { CategoriesMenu } from './categoriesMenu/categoriesMenu';
import { selectUser } from '../../../store/selectors';
import { UserMenu } from './userMenu/userMenu';
import { HeaderBtn } from './headerBtn/headerBtn';
import { ReactComponent as Grid } from '../../../img/icon/grid.svg';
import { ReactComponent as Hammer } from '../../../img/icon/hammer.svg';
import { ReactComponent as Bank } from '../../../img/icon/bank.svg';
import { ReactComponent as Law } from '../../../img/icon/law.svg';
import { ReactComponent as Info } from '../../../img/icon/info.svg';
import { Authentication } from '../authentication/authentication';
import { useCallback } from 'react';

export const Header = memo(({ headerRef }) => {
	const { isAuth } = useAuth();
	const href = useHref();
	const navigate = useNavigate();

	const { userType, firstName } = useSelector(selectUser);

	const [categoriesOpen, setCategoriesOpen] = useState(false);
	const [loginOpen, setLoginOpen] = useState(false);

	const navigateOnHowDoesThisWork = useCallback(() => {
		navigate('howDoesThisWork');
	}, []);

	const navigateOnInformation = useCallback(() => {
		navigate('information');
	}, []);
	return (
		<>
			<header ref={headerRef}>
				<div
					style={
						href === '/editorPage'
							? { padding: '0 50px 0', maxWidth: 'none' }
							: {}
					}
					className={` ${s.container}`}>
					<div className={s.leftBlock}>
						<BurgerMenu
							clickFunction={() => setCategoriesOpen(!categoriesOpen)}
						/>
					</div>

					<div className={s.rightBlock}>
						<SearchIcon className={s.icon} />
						<BookmarkIcon className={s.icon} />
						<BellIcon className={s.icon} />

						{isAuth ? (
							<UserMenu
								userType={userType}
								firstName={firstName} // exitFunction={handlerExit}
							/>
						) : (
							<button className={s.loginBtn} onClick={() => setLoginOpen(true)}>
								<UserIcon className={s.icon} />
								{'Войти'}
							</button>
						)}
					</div>
				</div>

				<div
					style={
						href === '/editorPage'
							? { padding: '0 50px 0', maxWidth: 'none' }
							: {}
					}
					className={`${s.btns} ${s.container}`}>
					<HeaderBtn
						icon={<Grid />}
						text={'Как это работает?'}
						clickFunction={navigateOnHowDoesThisWork}
					/>
					<HeaderBtn icon={<Hammer />} text={'Подать в суд'} />
					<HeaderBtn icon={<Bank />} text={'Подсудность'} />
					<HeaderBtn icon={<Law />} text={'Юристы'} />
					<HeaderBtn
						icon={<Info />}
						text={'Информация'}
						clickFunction={navigateOnInformation}
					/>
				</div>

				<div
					style={categoriesOpen ? { maxHeight: '300px' } : {}}
					className={s.categoriesMenuContainer}>
					<CategoriesMenu />
				</div>

				{loginOpen ? (
					<Authentication closeWindow={() => setLoginOpen(false)} />
				) : (
					<></>
				)}

				<FlagLine />
			</header>
		</>
	);
});
