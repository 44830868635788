import s from './inputEditBlock.module.scss';
import { ReactComponent as Pen } from '../../../../img/pen.svg';
import { Input } from '../../../common/input/input';
import { memo, useCallback, useState } from 'react';

export const InputEditBlock = memo(
	({
		title,
		inputType,
		inputValue = null,
		placeholder,
		submitFunction = () => {},
		maxLength = null,
	}) => {
		const [isEdit, setIsEdit] = useState(false);

		const [value, setValue] = useState(inputValue === null ? '' : inputValue);

		const handler = useCallback(
			(e) => {
				if (maxLength === null) return setValue(e.target.value);

				if (e.target.value.length <= maxLength) setValue(e.target.value);
			},
			[maxLength]
		);

		const handlerSave = useCallback(
			(e) => {
				e.preventDefault();

				submitFunction(value);
				setValue('');
				setIsEdit((prev) => !prev);
			},
			[value]
		);

		return (
			<form className={s.form} onSubmit={handlerSave}>
				<div className={s.form__row1}>
					<p>{title}:</p>

					{!isEdit && (
						<button
							className={s.btn}
							onClick={() => setIsEdit((prev) => !prev)}>
							<Pen />
						</button>
					)}
				</div>

				<div className={`${s.form__row2} ${isEdit && s.form__row2_edit}`}>
					{isEdit ? (
						<Input
							classes={s.input}
							type={inputType}
							inputValue={value}
							changeFunction={handler}
							placeholder={placeholder}
							disabled={!isEdit}
						/>
					) : (
						<>
							{inputValue || (
								<p className={s.form__defaultText}>Не заполнено</p>
							)}
						</>
					)}

					{isEdit && (
						<button type='submit' className={s.btn}>
							Сохранить
						</button>
					)}
				</div>
			</form>
		);
	}
);
