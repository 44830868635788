import { createCookie } from '../cookie/createCookie';
import { setUser } from '../../../../store/features/user/userSlice';

export function userLogin(user, token, isRemember, dispatch) {
	dispatch(setUser(user));

	if (isRemember) {
		const cookieTimeLive = 60 * 60 * 24 * 7; // сек * мин * час * дн

		createCookie('userAuth', token, cookieTimeLive);
		return;
	}

	createCookie('userAuth', token);
}
