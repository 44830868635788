import { memo, useCallback, useMemo, useState } from 'react';
import s from '../../authentication.module.scss';
import { ReactComponent as GosUslygiIcon } from '../../../../../img/loginIcons/gosUslygiIcon.svg';
import { ReactComponent as Arrow } from '../../../../../img/linkArrow.svg';
import licenseAgreement from '../../../../../documents/Лицензионное_соглашение.pdf';
import privacyPolicy from '../../../../../documents/Политика_конфиденциальности.pdf';
import offerSales from '../../../../../documents/Оферта_продажи_программных_продуктов.pdf';
import { Checkbox } from '../../../checkbox/checkbox';
import { FormCard } from '../../../formCard/formCard';
import { Input } from '../../../input/input';
import { Button } from '../../../button/button';
import { ActionWindow } from '../../actionWindow/actionWindow';
import { formatPhoneNumberForDataBase } from '../../../../../core/utils/functions/inputValidation/formatPhoneNumber/formatPhoneNumberForDataBase';
import { getSMSCode } from '../../../../../store/features/user/thunks';

export const LoginByPhone = memo(
	({
		submitFn,
		navigateClickFn,
		phoneValue,
		changePhoneFn,
		smsCodeValue,
		changeSMSCodeFn,
		isRemember,
		changeIsRememberFn,
		openRegisterFn,
		error = '',
		setError = { setError },
		dispatch,
	}) => {
		const PHONE_LOGIN_TYPE = 'PHONE_LOGIN_TYPE';
		const SMS_CODE_TYPE = 'SMS_CODE_TYPE';

		const [windowType, setWindowType] = useState(PHONE_LOGIN_TYPE);

		const [isAgreeOne, setIsAgreeOne] = useState(false);
		const [isAgreeTwo, setIsAgreeTwo] = useState(false);

		const textIsAgreeOne = useMemo(
			() => (
				<p>
					Я согласен на обработку персональных данных и соглашаюсь c{' '}
					<a href={privacyPolicy} target='_blank'>
						Политикой конфиденциальности
					</a>
				</p>
			),
			[]
		);
		const textIsAgreeTwo = useMemo(
			() => (
				<p>
					Я ознакомлен и соглашаюсь с условиями{' '}
					<a href={licenseAgreement} target='_blank'>
						Лицензионного соглашения
					</a>{' '}
					и{' '}
					<a href={offerSales} target='_blank'>
						Офертой продажи программных продуктов
					</a>
				</p>
			),
			[]
		);

		const handlerChangeIsAgreeOne = useCallback(
			() => setIsAgreeOne((prev) => !prev),
			[]
		);
		const handlerChangeIsAgreeTwo = useCallback(
			() => setIsAgreeTwo((prev) => !prev),
			[]
		);

		const handlerNavigate = useCallback(() => {
			setWindowType(PHONE_LOGIN_TYPE);
			setError('');
		}, [PHONE_LOGIN_TYPE]);

		const sendSMSCode = useCallback(() => {
			const phoneForDataBase = formatPhoneNumberForDataBase(phoneValue);

			dispatch(
				getSMSCode({
					phone: phoneForDataBase,
					setError,
					openActionWindow: () => setWindowType(SMS_CODE_TYPE),
				})
			);
		}, [phoneValue, SMS_CODE_TYPE]);

		switch (windowType) {
			case SMS_CODE_TYPE:
				return (
					<ActionWindow
						submitFn={submitFn}
						title='На указанный номер телефона отправлено сообщение. Введите код из SMS.'
						navigateClickFn={handlerNavigate}
						inputValue={smsCodeValue}
						inputChangeFn={changeSMSCodeFn}
						inputPlaceholder='----'
						error={error}
						submitBtnText='Войти'
					/>
				);

			default:
				return (
					<FormCard classes={s.window} submitFunction={sendSMSCode}>
						<div className={`${s.navigate}`} onClick={navigateClickFn}>
							Войти по email
							<Arrow />
						</div>

						<h4>Вход по телефону</h4>

						<div className={error ? s.inputError : s.inputBlock}>
							<Input
								type={'tel'}
								inputValue={phoneValue}
								changeFunction={changePhoneFn}
								placeholder='+7 (ХХХ) ХХХ ХХ-ХХ'
								maxLength={18}
								required={true}
							/>
						</div>

						{error && <div className={s.error}>{error}</div>}

						<div className={s.rememberBlock}>
							<Checkbox
								text={'Запомнить'}
								isChecked={isRemember}
								changeFunction={changeIsRememberFn}
							/>
						</div>

						<div className={s.entryBlock}>
							<Button
								type='submit'
								classes={s.btn}
								text={'Получить код'}
								disabled={!(isAgreeOne === true && isAgreeTwo === true)}
							/>

							<div>
								<Checkbox
									text={textIsAgreeOne}
									isChecked={isAgreeOne}
									changeFunction={handlerChangeIsAgreeOne}
								/>

								<Checkbox
									text={textIsAgreeTwo}
									isChecked={isAgreeTwo}
									changeFunction={handlerChangeIsAgreeTwo}
								/>
							</div>
						</div>

						<div className={s.loginOptionsBlock}>
							<p>Другие способы входа</p>

							<div className={s.icons}>
								<GosUslygiIcon />
								<GosUslygiIcon />
								<GosUslygiIcon />
								<GosUslygiIcon />
							</div>
						</div>

						<p className={s.authTypeBlock}>
							Нет учетной записи?{' '}
							<span onClick={openRegisterFn}>Зарегистрироваться</span>
						</p>
					</FormCard>
				);
		}
	}
);
