import { setStateDocument } from '../../../../store/features/document/documentSlice';
import { setOrganizationData } from '../../../../store/features/user/organizationData/organizationDataSlice';
import {
	setActualAddress,
	setINN,
	setPassportDetails,
	setSNILS,
} from '../../../../store/features/user/userData/userDataSlice';
import { setUser } from '../../../../store/features/user/userSlice';
import { deleteCookie } from '../cookie/deleteCookie';

export const userLogout = ({ userType, dispatch }) => {
	deleteCookie('userAuth');
	dispatch(setUser());
	dispatch(setStateDocument());

	switch (userType) {
		case 0:
			dispatch(setPassportDetails());
			dispatch(setINN());
			dispatch(setSNILS());
			dispatch(setActualAddress());
			break;

		case 3:
			dispatch(setOrganizationData());
			break;
	}
};
