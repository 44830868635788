import { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useHref } from 'react-router-dom';
import s from './app.module.css';
import { Header } from '../common/header/header';
import { MainPage } from '../pages/mainPage/mainPage';
import { EditorPage } from '../pages/editorPage/editorPage';
import { CategoriesPage } from '../pages/categoriesPage/categoriesPage';
import { CategoriesPageMobile } from '../pages/categoriesPage/categoriesPageMobile/categoriesPageMobile';
import { FormDocumentPage } from '../pages/formDocumentPage/formDocumentPage';
import { getServiceList } from '../../store/features/services/thunks';
import { SignaturePage } from '../pages/signaturePage/signaturePage';
import { VerifyPage } from '../pages/verifyPage/verifyPage';
import { Footer } from '../common/footer/footer';
import { getUser } from '../../store/features/user/thunks';
import { getOrganization } from '../../store/features/user/organizationData/thunks';
import { getUserData } from '../../store/features/user/userData/thunks';
import { BottomMenuMobile } from '../common/bottomMenuMobile/bottomMenuMobile';
import { UserOfficePage } from '../pages/officePage/userOffice/userOffice';
import { HeaderMobile } from '../common/header/headerMobile/headerMobile';
import { UserOfficeMobilePage } from '../pages/officePage/userOffice/userOfficeMobile/userOfficeMobile';
import { AdminOffice } from '../pages/officePage/adminOffice/adminOffice';
import { ServicePage } from '../pages/servicePage/servicePage';
import { selectUser } from '../../store/selectors';
import { getUserToken } from '../../core/utils/functions/user/getUserToken';
import { InformationPage } from '../pages/informationPage/informationPage';
import { useResize } from '../../core/hooks/useResize';
import { HowDoesThisWork } from '../pages/howDoesThisWorkPage/howDoesThisWorkPage';

export const WindowWidthContext = createContext();

export const App = () => {
	const dispatch = useDispatch();
	const href = useHref();
	const { width, isDesktop, isTabled, isPhone } = useResize();

	const appRef = useRef(null);
	const mainRef = useRef(null);
	const headerRef = useRef(null);

	const { id, userType } = useSelector(selectUser);

	const [header, setHeader] = useState(<Header headerRef={headerRef} />);
	const [footer, setFooter] = useState(<Footer />);

	const windowWidthContextValue = {
		windowWidth: window.innerWidth,
		windowWidthTablet: 900,
		windowWidthPhone: 685,
	};

	useEffect(() => {
		dispatch(getServiceList());
	}, []);

	useEffect(() => {
		const userToken = getUserToken();

		if (userToken !== undefined) {
			dispatch(getUser(userToken));
		}
	}, []);

	useEffect(() => {
		if (id !== null) {
			const userToken = getUserToken();

			switch (userType) {
				case 0:
					dispatch(getUserData(userToken));
					break;

				case 3:
					dispatch(getOrganization(userToken));
					break;
			}
		}
	}, [id]);

	useEffect(() => {
		if (isDesktop) {
			setHeader(<Header headerRef={headerRef} />);
			setFooter(<Footer />);
			mainRef.current.style.margin = '';
			appRef.current.style.overflowX = '';
			return;
		}

		setHeader(<HeaderMobile headerRef={headerRef} />);
		setFooter(<BottomMenuMobile />);
		mainRef.current.style.margin = '0 0 5rem';
		appRef.current.style.overflowX = 'hidden';
	}, [isDesktop]);

	return (
		<WindowWidthContext.Provider value={windowWidthContextValue}>
			<div ref={appRef} className={s.container}>
				{header}

				<main
					ref={mainRef}
					style={
						href === '/editorPage'
							? {
									padding: '0px 50px 30px',
									boxSizing: 'border-box',
							  }
							: {}
					}>
					<Routes>
						<Route index element={<MainPage />} />
						{/* {windowWidthContextValue.windowWidth >
						windowWidthContextValue.windowWidthTablet ? ( */}
						{isDesktop ? (
							<Route path='categories/*' element={<CategoriesPage />} />
						) : (
							<Route path='categories/*' element={<CategoriesPageMobile />} />
						)}
						{/* {windowWidthContextValue.windowWidth >
							windowWidthContextValue.windowWidthTablet && ( */}
						{isDesktop && (
							<Route
								path='categories/:categoryName/:serviceName'
								element={<ServicePage />}
							/>
						)}

						<Route path='services/form' element={<FormDocumentPage />} />
						{/* {windowWidthContextValue.windowWidth >
						windowWidthContextValue.windowWidthTablet ? ( */}
						{isDesktop ? (
							<Route path='userOffice/*' element={<UserOfficePage />} />
						) : (
							<Route path='userOffice/*' element={<UserOfficeMobilePage />} />
						)}
						<Route path='adminOffice/*' element={<AdminOffice />} />

						<Route path='sign/:signNumber' element={<SignaturePage />} />
						<Route path='verify/:verifyToken' element={<VerifyPage />} />
						<Route path='editorPage' element={<EditorPage />} />
						<Route path='information' element={<InformationPage />} />
						<Route path='howDoesThisWork' element={<HowDoesThisWork />} />
					</Routes>
				</main>

				{href !== '/editorPage' && footer}
			</div>
		</WindowWidthContext.Provider>
	);
};
