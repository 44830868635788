import { useNavigate, Route, Routes, useHref } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import s from './categoriesPage.module.css';
import { selectServices } from '../../../store/selectors';
import { useAuth } from '../../../core/hooks/useAuth';
import { Navigation } from '../../common/navigation/navigation';
// import { openFormToUser } from '../../../core/utils/functions/openFormToUser';
// import { Button } from '../../common/button/button';
import { Section } from '../../common/section/section';
import { MainMenu } from '../../common/mainMenu/mainMenu';
import { MenuItem } from '../../common/menuItem/menuItem';
import { ReactComponent as Law } from '../../../img/icon/law.svg';
import { ReactComponent as Home } from '../../../img/icon/home.svg';
import { ReactComponent as Car } from '../../../img/icon/car.svg';
import { ReactComponent as Family } from '../../../img/icon/family.svg';
import { ReactComponent as Money } from '../../../img/icon/money.svg';
import { ReactComponent as Work } from '../../../img/icon/work.svg';
import { Favorites } from '../officePage/userOffice/favorites/favorites';
import { PageContainer } from '../../common/pageContainer/pageContainer';

export const CategoriesPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const href = useHref();

	const { isAuth } = useAuth();

	const serviceList = useSelector(selectServices).serviceList;

	const handlerNavigation = (path) => navigate(path);

	return (
		<PageContainer>
			<Navigation
				linkList={[
					{
						text: 'Главная',
						path: '/',
					},
					{
						text: 'Категории',
					},
				]}
			/>

			<Section className={s.section}>
				<MainMenu
					classes={s.categoryList}
					list={
						<>
							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Law />}
									name={'Ущерб'}
									clickFunction={() => handlerNavigation('damage')}
								/>

								<div
									style={
										href === '/categories/damage'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Home />}
									name={'Недвижимость и имущество'}
									clickFunction={() => handlerNavigation('property')}
								/>

								<div
									style={
										href === '/categories/property'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Car />}
									name={'Транспорт'}
									clickFunction={() => handlerNavigation('transport')}
								/>

								<div
									style={
										href === '/categories/transport'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Family />}
									name={'Семья и дети'}
									clickFunction={() => handlerNavigation('family')}
								/>

								<div
									style={
										href === '/categories/family'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Money />}
									name={'Пособия и выплаты'}
									clickFunction={() => handlerNavigation('benefitsAndPayments')}
								/>
								<div
									style={
										href === '/categories/benefitsAndPayments'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Work />}
									name={'Работа'}
									clickFunction={() => handlerNavigation('work')}
								/>

								<div
									style={
										href === '/categories/work'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>
						</>
					}
				/>
			</Section>

			<div className={s.content}>
				<Routes>
					<Route
						path='damage/*'
						element={<Favorites handlerNavigation={handlerNavigation} />}
					/>
					<Route path='property' element={<div>1</div>} />
					<Route path='transport' element={<div>2</div>} />
					<Route path='family' element={<div>3</div>} />
					<Route path='benefitsAndPayments' element={<div>4</div>} />
					<Route path='work' element={<div>5</div>} />
				</Routes>
			</div>
		</PageContainer>
	);
};
