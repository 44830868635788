import { Button } from '../../../common/button/button';
import s from './usefulBlock.module.scss';
import { ReactComponent as LinkArrowFlag } from '../../../../img/linkArrowFlag.svg';
import { InformationElement } from './informationElement/informationElement';

export const UsefulBlock = () => {
	return (
		<div className={s.container}>
			<h3 className={`title`}>Полезное</h3>

			<div>
				<div className={s.left}>
					{/* <p>Узнайте о всех функциях и преимуществах:</p> */}

					<div className={s.btns}>
						<InformationElement title={'Как получить консультацию'} />
						<InformationElement title={'Как получить консультацию'} />
						<InformationElement title={'Как получить консультацию'} />
						<InformationElement title={'Как получить консультацию'} />
					</div>
				</div>

				<div className={s.right}></div>
			</div>
		</div>
	);
};
