import { memo } from 'react';
import s from '../authentication.module.scss';

export const InformationWindow = memo(({ message }) => {
	return (
		<div className={s.window}>
			<h4>{message}</h4>
		</div>
	);
});
