import { createSlice } from '@reduxjs/toolkit';
// import { getUserData } from './thunks';

const initialState = {
	passportDetails: {
		series: null,
		number: null,
		issued: '',
		dateOfIssue: null,
		dateOfBirth: null,
		departmentCode: null,
		registrationAddress: '',
	},
	inn: null,
	snils: null,
	actualAddress: '',
};

export const userDataSlice = createSlice({
	name: 'userData',
	initialState,

	reducers: {
		setPassportDetails: (state, action) => {
			state.passportDetails = action.payload
				? action.payload
				: initialState.passportDetails;
		},

		setINN: (state, action) => {
			state.inn = action.payload ? action.payload : initialState.inn;
		},

		setSNILS: (state, action) => {
			state.snils = action.payload ? action.payload : initialState.snils;
		},

		setActualAddress: (state, action) => {
			state.actualAddress = action.payload
				? action.payload
				: initialState.actualAddress;
		},
	},

	extraReducers: {},
});

export const { setPassportDetails, setINN, setSNILS, setActualAddress } =
	userDataSlice.actions;
export const userDataReducer = userDataSlice.reducer;
