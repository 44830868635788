import { createAsyncThunk } from '@reduxjs/toolkit';
import { authApi, organizationApi, userApi, verifyApi } from '../../../api/api';
import { setUser, setIsVerifyPhone, setFullName } from './userSlice';
import { userLogin } from '../../../core/utils/functions/user/userLogin';
// import { setOrganizationData } from './organizationData/organizationDataSlice';

export const userRegistration = createAsyncThunk(
	'user/userRegistration',
	async (
		{
			firstName,
			lastName,
			middleName,
			email,
			phone,
			password,
			setIsConfirmationEmailSent,
			setError,
		},
		{ dispatch }
	) => {
		userApi
			.register({
				firstName,
				lastName,
				middleName,
				email,
				phone,
				password,
			})
			.then((res) => {
				// console.log(res);
				setIsConfirmationEmailSent(true);
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const organizationRegistration = createAsyncThunk(
	'user/organizationRegistration',
	async (
		{
			legalName,
			inn,
			address,
			firstName,
			lastName,
			middleName,
			email,
			phone,
			password,
			setIsConfirmationEmailSent,
			setError,
		},
		{ dispatch }
	) => {
		organizationApi
			.register({
				legalName,
				inn,
				address,
				firstName,
				lastName,
				middleName,
				email,
				phone,
				password,
			})
			.then((res) => {
				// console.log(res);
				setIsConfirmationEmailSent(true);
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const getUser = createAsyncThunk(
	'user/getUser',
	async (userToken, { rejectWithValue, dispatch }) => {
		userApi
			.getUser(userToken)
			.then((res) => {
				// console.log(res);
				dispatch(setUser(res.data));
			})
			.catch((err) => {
				console.log('err', err);
				// setError(err.response.data.error);
			});
	}
);

export const authByEmail = createAsyncThunk(
	'user/authByEmail',
	async ({ email, password, isRemember, setError }, { dispatch }) => {
		// console.log(email);
		authApi
			.emailAuth(email, password)
			.then((res) => {
				// console.log(res.data);
				userLogin(res.data.user, res.data.token, isRemember, dispatch);
			})
			.catch((err) => {
				// console.log(err);
				setError(err.response.data.error);
			});
	}
);

export const authByPhone = createAsyncThunk(
	'user/authByPhone',
	async ({ phone, smsCode, isRemember, setError }, { dispatch }) => {
		authApi
			.phoneAuth(phone, smsCode)
			.then((res) => {
				// console.log(res.data);
				userLogin(res.data.user, res.data.token, isRemember, dispatch);
			})
			.catch((err) => {
				console.log(err);
				setError(err.response.data.error);
			});
	}
);

export const getSMSCode = createAsyncThunk(
	'user/getSmsCode',
	async ({ phone, setError, openActionWindow }) => {
		setError('');

		authApi
			.getSMSCode(phone)
			.then((res) => {
				// console.log(res);

				if (res.status === 200) {
					openActionWindow();
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const verifySmsCode = createAsyncThunk(
	'user/verifySmsCode',
	async ({ phone, smsCode, setError }, { dispatch }) => {
		setError('');

		verifyApi
			.verifySms({ phone, sms: smsCode })
			.then((res) => {
				// console.log(res);

				if (res.status === 200) {
					dispatch(setIsVerifyPhone(res.data.isVerifyPhone));
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const verifyEmail = createAsyncThunk(
	'user/verifyEmail',
	async (
		{ email, setError = () => {}, openInformWindow = () => {} },
		{ dispatch }
	) => {
		setError('');

		verifyApi
			.verifyEmail(email)
			.then((res) => {
				// console.log(res);

				if (res.status === 200) {
					openInformWindow();
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const resetPassword = createAsyncThunk(
	'user/resetPassword',
	async ({ email, setError = () => {}, openInformWindow = () => {} }) => {
		setError('');

		userApi
			.resetPassword(email)
			.then((res) => {
				if (res.status === 200) {
					openInformWindow();
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err.response.data.error);
			});
	}
);

export const setUserFullName = createAsyncThunk(
	'user/userData/setUserFullName',
	async (
		{ userToken, firstName, lastName, middleName, handlerError = () => {} },
		{ dispatch }
	) => {
		handlerError('');

		userApi
			.setUserFullName({
				userToken,
				firstName,
				lastName,
				middleName,
			})
			.then((res) => {
				// console.log(res);
				dispatch(
					setFullName({
						firstName,
						lastName,
						middleName,
					})
				);
			})
			.catch((err) => {
				console.log(err);
				handlerError(err.response.data.error);
			});
	}
);
