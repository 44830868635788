import { configureStore } from '@reduxjs/toolkit';
import documentReducer from './features/document/documentSlice';
import { userReducer } from './features/user/userSlice';
import servicesReducer from './features/services/servicesSlice';
import { organizationDataReducer } from './features/user/organizationData/organizationDataSlice';
import { userDataReducer } from './features/user/userData/userDataSlice';

export const store = configureStore({
	reducer: {
		user: userReducer,
		services: servicesReducer,
		document: documentReducer,
		organizationData: organizationDataReducer,
		userData: userDataReducer,
	},

	// middleware: (getDefaultMiddleware) =>
	// 	getDefaultMiddleware({
	// 		serializableCheck: false,
	// 	}),
});
