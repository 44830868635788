export const imgConverterToBase64 = (file = '') => {
	return new Promise((resolve, reject) => {
		if (!file) reject('File not found');

		let reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result);
	});
};
