import { memo, useCallback, useState } from 'react';
import s from './input.module.scss';

export const InputFile = memo(
	({ file, changeFunction, icon, text, fileType, required = false }) => {
		return (
			<label className={s.inputFile}>
				{icon}
				<input
					type={'file'}
					// value={file}
					onChange={changeFunction}
					accept={fileType}
					required={required}
				/>
				{file ? file.name : text}
			</label>
		);
	}
);
