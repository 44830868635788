import { memo, useCallback, useState } from 'react';
import s from '../authentication.module.scss';
import { Button } from '../../button/button';
import { RegisterOfIndividual } from './registerOfIndividual/registerOfIndividual';
import { RegisterOfOrganization } from './registerOfOrganization/registerOfOrganization';

export const Register = memo(({ setAuthType, LOGIN_TYPE, error, setError }) => {
	const INDIVIDUAL_TYPE = 'INDIVIDUAL_TYPE';
	const ORGANIZATION_TYPE = 'ORGANIZATION_TYPE';

	const [registerType, setRegisterType] = useState('');

	const handlerRegisterType = useCallback(
		(registerType) => {
			setError('');
			setRegisterType(registerType);
		},
		[registerType]
	);

	const openLogin = useCallback(() => {
		setAuthType(LOGIN_TYPE);
		setRegisterType('');
		setError('');
	}, [LOGIN_TYPE]);

	switch (registerType) {
		case INDIVIDUAL_TYPE:
			return (
				<RegisterOfIndividual
					handlerRegisterType={handlerRegisterType}
					error={error}
					setError={setError}
				/>
			);

		case ORGANIZATION_TYPE:
			return (
				<RegisterOfOrganization
					handlerRegisterType={handlerRegisterType}
					error={error}
					setError={setError}
				/>
			);

		default:
			return (
				<div className={s.window}>
					<h4>Зарегистрироваться</h4>

					<Button
						text={'Как физическое лицо'}
						clickFunction={() => handlerRegisterType(INDIVIDUAL_TYPE)}
					/>

					<Button
						text={'Как юридическое лицо'}
						clickFunction={() => handlerRegisterType(ORGANIZATION_TYPE)}
					/>

					<p className={s.authTypeBlock}>
						Есть учетная запись? <span onClick={openLogin}>Войти</span>
					</p>
				</div>
			);
	}
});
