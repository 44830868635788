import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	id: null,
	userType: null,
	email: '',
	firstName: '',
	lastName: '',
	middleName: '',
	phone: '',
	isVerifyPhone: null,
	isVerifyEmail: null,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,

	reducers: {
		setUser: (state, action) =>
			(state = action.payload ? action.payload : initialState),

		setIsVerifyPhone: (state, action) => {
			state.isVerifyPhone = action.payload ? action.payload : initialState;
		},

		setFullName: (state, action) => {
			state.firstName = action.payload.firstName
				? action.payload.firstName
				: state.firstName;

			state.lastName = action.payload.lastName
				? action.payload.lastName
				: state.lastName;

			state.middleName = action.payload.middleName
				? action.payload.middleName
				: state.middleName;
		},
	},

	extraReducers: {},
});

export const { setUser, setIsVerifyPhone, setFullName } = userSlice.actions;
export const userReducer = userSlice.reducer;
