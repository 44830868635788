import { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoginByEmail } from './loginByEmail/loginByEmail';
import { LoginByPhone } from './loginByPhone/loginByPhone';
import {
	authByEmail,
	authByPhone,
} from '../../../../store/features/user/thunks';
import { formatInputPhoneNumber } from '../../../../core/utils/functions/inputValidation/formatPhoneNumber/formatInputPhoneNumber';
import { formatPhoneNumberForDataBase } from '../../../../core/utils/functions/inputValidation/formatPhoneNumber/formatPhoneNumberForDataBase';

export const Login = memo(({ openRegister, error, setError }) => {
	const dispatch = useDispatch();

	const EMAIL_LOGIN_TYPE = 'EMAIL_LOGIN_TYPE';
	const PHONE_LOGIN_TYPE = 'PHONE_LOGIN_TYPE';

	const [windowType, setWindowType] = useState(EMAIL_LOGIN_TYPE);

	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [smsCode, setSmsCode] = useState('');

	const [isRemember, setIsRemember] = useState(false);

	const handlerEmail = useCallback((e) => setEmail(e.target.value), []);
	const handlerPassword = useCallback((e) => setPassword(e.target.value), []);

	const handlerPhone = useCallback((e) => {
		const formattedPhoneNumber = formatInputPhoneNumber(e.target.value);
		setPhone(formattedPhoneNumber);
	}, []);
	const handlerSmsCode = useCallback((e) => setSmsCode(e.target.value), []);

	const handlerNavigate = useCallback(
		(windowType) => {
			setWindowType(windowType);
			setError('');
		},
		[windowType]
	);
	const handlerChangeIsRemember = useCallback(
		() => setIsRemember((prev) => !prev),
		[]
	);

	const loginByEmail = useCallback(() => {
		setError('');
		dispatch(authByEmail({ email, password, isRemember, setError }));
	}, [email, password, isRemember]);

	const loginByPhone = useCallback(() => {
		setError('');
		const phoneForDataBase = formatPhoneNumberForDataBase(phone);

		dispatch(
			authByPhone({
				phone: phoneForDataBase,
				smsCode,
				isRemember,
				setError,
			})
		);
	}, [phone, smsCode, isRemember]);

	switch (windowType) {
		case PHONE_LOGIN_TYPE:
			return (
				<LoginByPhone
					submitFn={loginByPhone}
					navigateClickFn={() => handlerNavigate(EMAIL_LOGIN_TYPE)}
					phoneValue={phone}
					changePhoneFn={handlerPhone}
					smsCodeValue={smsCode}
					changeSMSCodeFn={handlerSmsCode}
					isRemember={isRemember}
					changeIsRememberFn={handlerChangeIsRemember}
					openRegisterFn={openRegister}
					error={error}
					setError={setError}
					dispatch={dispatch}
				/>
			);

		default:
			return (
				<LoginByEmail
					submitFn={loginByEmail}
					navigateClickFn={() => handlerNavigate(PHONE_LOGIN_TYPE)}
					emailValue={email}
					changeEmailFn={handlerEmail}
					passwordValue={password}
					changePasswordFn={handlerPassword}
					isRemember={isRemember}
					changeIsRememberFn={handlerChangeIsRemember}
					openRegisterFn={openRegister}
					error={error}
					setError={setError}
					dispatch={dispatch}
				/>
			);
	}
});
