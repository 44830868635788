import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import s from '../../authentication.module.scss';
import { Button } from '../../../button/button';
import { Input } from '../../../input/input';
import { InputPassword } from '../../../input/inputPassword';
import { Checkbox } from '../../../checkbox/checkbox';
import { ReactComponent as Arrow } from '../../../../../img/linkArrow.svg';
import licenseAgreement from '../../../../../documents/Лицензионное_соглашение.pdf';
import privacyPolicy from '../../../../../documents/Политика_конфиденциальности.pdf';
import offerSales from '../../../../../documents/Оферта_продажи_программных_продуктов.pdf';
import { FormCard } from '../../../formCard/formCard';
import { formatInputPhoneNumber } from '../../../../../core/utils/functions/inputValidation/formatPhoneNumber/formatInputPhoneNumber';
import { formatPhoneNumberForDataBase } from '../../../../../core/utils/functions/inputValidation/formatPhoneNumber/formatPhoneNumberForDataBase';
import { organizationRegistration } from '../../../../../store/features/user/thunks';
import { InformationWindow } from '../../informationWindow/informationWindow';

export const RegisterOfOrganization = memo(
	({ handlerRegisterType, error, setError }) => {
		const dispatch = useDispatch();

		const [isAgreeOne, setIsAgreeOne] = useState(false);
		const [isAgreeTwo, setIsAgreeTwo] = useState(false);

		const [organizationName, setOrganizationName] = useState('');
		const [INN, setINN] = useState('');
		const [address, setAddress] = useState('');
		const [firstName, setFirstName] = useState('');
		const [lastName, setLastName] = useState('');
		const [middleName, setMiddleName] = useState('');
		const [email, setEmail] = useState('');
		const [phone, setPhone] = useState('');
		const [password, setPassword] = useState('');
		const [repeatPassword, setRepeatPassword] = useState('');

		const [isConfirmationEmailSent, setIsConfirmationEmailSent] =
			useState(false);

		const handlerOrganizationName = useCallback(
			(e) => setOrganizationName(e.target.value),
			[]
		);
		const handlerINN = useCallback((e) => {
			if (e.target.value.length <= 10) {
				setINN(e.target.value);
			}
		}, []);
		const handlerAddress = useCallback((e) => setAddress(e.target.value), []);
		const handlerFirstName = useCallback(
			(e) => setFirstName(e.target.value),
			[]
		);
		const handlerLastName = useCallback((e) => setLastName(e.target.value), []);
		const handlerMiddleName = useCallback(
			(e) => setMiddleName(e.target.value),
			[]
		);
		const handlerEmail = useCallback((e) => setEmail(e.target.value), []);
		const handlerPhone = useCallback((e) => {
			const formattedPhoneNumber = formatInputPhoneNumber(e.target.value);
			setPhone(formattedPhoneNumber);
		}, []);
		const handlerPassword = useCallback((e) => setPassword(e.target.value), []);
		const handlerRepeatPassword = useCallback(
			(e) => setRepeatPassword(e.target.value),
			[]
		);

		const handlerRegister = () => {
			setError('');

			if (password !== repeatPassword) {
				// console.log('repeatPassword false');
				setError('Пароли не совпадают!');
				return;
			}

			const phoneForDataBase = formatPhoneNumberForDataBase(phone);

			dispatch(
				organizationRegistration({
					legalName: organizationName,
					inn: INN,
					address,
					firstName,
					lastName,
					middleName,
					email,
					phone: phoneForDataBase,
					password,
					setIsConfirmationEmailSent,
					setError,
				})
			);
		};

		const textIsAgreeOne = useMemo(
			() => (
				<p>
					Я согласен на обработку персональных данных и соглашаюсь c{' '}
					<a href={privacyPolicy} target='_blank'>
						Политикой конфиденциальности
					</a>
				</p>
			),
			[]
		);
		const textIsAgreeTwo = useMemo(
			() => (
				<p>
					Я ознакомлен и соглашаюсь с условиями{' '}
					<a href={licenseAgreement} target='_blank'>
						Лицензионного соглашения
					</a>{' '}
					и{' '}
					<a href={offerSales} target='_blank'>
						Офертой продажи программных продуктов
					</a>
				</p>
			),
			[]
		);

		const handlerChangeIsAgreeOne = useCallback(
			() => setIsAgreeOne((prev) => !prev),
			[]
		);
		const handlerChangeIsAgreeTwo = useCallback(
			() => setIsAgreeTwo((prev) => !prev),
			[]
		);

		switch (isConfirmationEmailSent) {
			case false:
				return (
					<FormCard classes={s.window} submitFunction={handlerRegister}>
						<div
							className={`${s.btnBack}`}
							onClick={() => handlerRegisterType('')}>
							<Arrow />
							Вернуться
						</div>

						<h4>Регистрация</h4>

						<div className={s.inputBlock}>
							<Input
								inputValue={organizationName}
								changeFunction={handlerOrganizationName}
								placeholder='Название организации*'
								required={true}
							/>
							<Input
								inputValue={INN}
								changeFunction={handlerINN}
								placeholder='ИНН*'
								required={true}
							/>
							<Input
								inputValue={address}
								changeFunction={handlerAddress}
								placeholder='Юридический адрес*'
								required={true}
							/>
							<Input
								inputValue={lastName}
								changeFunction={handlerLastName}
								placeholder='Фамилия представителя*'
								required={true}
							/>
							<Input
								inputValue={firstName}
								changeFunction={handlerFirstName}
								placeholder='Имя представителя*'
								required={true}
							/>
							<Input
								inputValue={middleName}
								changeFunction={handlerMiddleName}
								placeholder='Отчество представителя'
								required={false}
							/>
							<Input
								type={'email'}
								inputValue={email}
								changeFunction={handlerEmail}
								placeholder='e-mail*'
								required={true}
							/>
							<Input
								type={'tel'}
								inputValue={phone}
								changeFunction={handlerPhone}
								placeholder='+7 (ХХХ) ХХХ ХХ-ХХ*'
								maxLength={18}
								required={true}
							/>

							<InputPassword
								inputValue={password}
								changeFunction={handlerPassword}
								placeholder='Пароль*'
								required={true}
							/>
							<InputPassword
								inputValue={repeatPassword}
								changeFunction={handlerRepeatPassword}
								placeholder='Повторите пароль*'
								required={true}
							/>
						</div>

						{error && <div className={s.error}>{error}</div>}
						<div className={s.entryBlock}>
							<Button
								type='submit'
								classes={s.btn}
								text={'Зарегистрироваться'}
								disabled={!(isAgreeOne === true && isAgreeTwo === true)}
							/>

							<div>
								<Checkbox
									text={textIsAgreeOne}
									isChecked={isAgreeOne}
									changeFunction={handlerChangeIsAgreeOne}
								/>

								<Checkbox
									text={textIsAgreeTwo}
									isChecked={isAgreeTwo}
									changeFunction={handlerChangeIsAgreeTwo}
								/>
							</div>
						</div>
					</FormCard>
				);

			case true:
				return (
					<InformationWindow
						message={
							'На указанный адрес электронной почты отправлено сообщение. Завершите регистрацию.'
						}
					/>
				);
		}
	}
);
