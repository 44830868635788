import { Button } from '../../../common/button/button';
import { FormCard } from '../../../common/formCard/formCard';
import { Input } from '../../../common/input/input';
import s from './governmentBlock.module.scss';
import { GovernmentAgenciesList } from './governmentAgenciesList/governmentAgenciesList';
import { ReactComponent as LinkArrowFlag } from '../../../../img/linkArrowFlag.svg';
import { Textarea } from '../../../common/textarea/textarea';

export const GovernmentBlock = () => {
	return (
		<div className={s.container}>
			<h3 className={`title`}>Государственные сервисы</h3>

			<div className={s.wrp}>
				<GovernmentAgenciesList />

				<FormCard title={'По любым вопросам'} classes={s.form}>
					<div className={s.content}>
						<p>заполните формы и мы обязательно свяжемся</p>

						<Input placeholder={'Имя'} />
						<Input placeholder={'E-mail'} />
						<Textarea rows={3} placeholder='Комменитарии' />

						<Button
							text={
								<>
									Отправить <LinkArrowFlag />
								</>
							}
						/>
					</div>
				</FormCard>
			</div>
		</div>
	);
};
