import s from '../authentication.module.scss';
import { FormCard } from '../../formCard/formCard';
import { Input } from '../../input/input';
import { Button } from '../../button/button';
import { ReactComponent as Arrow } from '../../../../img/linkArrow.svg';
import { memo } from 'react';

export const ActionWindow = memo(
	({
		submitFn,
		navigateClickFn,
		title,
		inputType = 'text',
		inputValue,
		inputChangeFn,
		inputPlaceholder,
		error = '',
		submitBtnText,
	}) => {
		return (
			<FormCard classes={s.form} submitFunction={submitFn}>
				<div className={`${s.btnBack}`} onClick={navigateClickFn}>
					<Arrow />
					Вернуться
				</div>

				<h4>{title}</h4>

				<div className={error ? s.inputError : s.inputBlock}>
					<Input
						type={inputType}
						inputValue={inputValue}
						changeFunction={inputChangeFn}
						placeholder={inputPlaceholder}
						required={true}
					/>
				</div>

				{error && <div className={s.error}>{error}</div>}

				<div className={s.entryBlock}>
					<Button type='submit' classes={s.btn} text={submitBtnText} />
				</div>
			</FormCard>
		);
	}
);
