import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import s from './editorPage.module.css';
import { ContentContainer } from './content/contentContainer';
import { useAuth } from '../../../core/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { getDirList } from '../../../store/features/services/thunks';
import { setUser } from '../../../store/features/user/userSlice';
import { deleteCookie } from '../../../core/utils/functions/cookie/deleteCookie';
import { Navigation } from '../../common/navigation/navigation';

export const EditorPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isAuth } = useAuth();

	useEffect(() => {
		dispatch(getDirList());
		// console.log('effect');
	}, []);

	useEffect(() => {
		// console.log('effect');
		if (!isAuth) {
			navigate('/');
		}
	}, [isAuth]);

	return (
		<div className={s.container}>
			<Navigation
				linkList={[
					{
						text: 'Главная',
						path: '/',
					},
					{
						text: 'Личный кабинет',
						path: '/adminOffice/moderation',
					},
					{ text: 'Конструктор документов' },
				]}
			/>

			<h2 className={`title ${s.title}`}>Конструктор документов</h2>

			<ContentContainer />
		</div>
	);
};
