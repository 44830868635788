import { memo } from 'react';
import s from './informationElement.module.scss';
import { Button } from '../../../../common/button/button';
import { ReactComponent as LinkArrowFlag } from '../../../../../img/linkArrowFlag.svg';
import { useToggle } from '../../../../../core/hooks/useToggle';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useOutsideClick } from '../../../../../core/hooks/useOutsideClick';
import { useMemo } from 'react';
import { useResize } from '../../../../../core/hooks/useResize';

export const InformationElement = memo(({ title }) => {
	const refInfo = useRef();
	const { isPhone } = useResize();

	const [openInfo, setOpenInfo] = useToggle(false);

	const btnText = useMemo(
		() => (
			<>
				{title} <LinkArrowFlag />
			</>
		),
		[title]
	);

	const handlerOpenInfo = useCallback(() => {
		setOpenInfo(!openInfo);
	}, []);

	const handlerCloseInfo = useCallback(() => {
		setOpenInfo(false);
	}, []);

	useOutsideClick(refInfo, handlerCloseInfo, openInfo);

	return (
		<div className={s.container}>
			<Button classes={s.btn} text={btnText} clickFunction={handlerOpenInfo} />

			{isPhone && (
				<div
					ref={refInfo}
					style={
						openInfo
							? {
									padding: '5rem 2rem',
									backgroundColor: '#fff',
									boxShadow: '1px 1px .4rem 0px rgba(0, 0, 0, 0.35)',
							  }
							: {}
					}
					className={s.info}></div>
			)}
		</div>
	);
});
