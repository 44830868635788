export const formatDepartmentCodeInPassport = (str) => {
	const reg = /^\d{0,4}$|^\d{3}-\d{0,3}$/;
	const prevStr = str.slice(0, -1);

	if (!reg.test(str)) {
		return prevStr;
	}

	if (str.length === 4) {
		return str[3] !== '-' ? str.slice(0, 3) + '-' + str[3] : str.slice(0, 3);
	}

	return str;
};
