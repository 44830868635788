import { memo, useCallback, useState } from 'react';
import s from '../../userInfo.module.scss';
import { Input } from '../../../../../../../common/input/input';
import { FormCard } from '../../../../../../../common/formCard/formCard';
import { Button } from '../../../../../../../common/button/button';
import { useDispatch } from 'react-redux';
import { setOrganization } from '../../../../../../../../store/features/user/organizationData/thunks';

export const OrganizationDataForm = memo(
	({ userToken, openInformWindow, error, handlerError }) => {
		const dispatch = useDispatch();

		const [organizationName, setOrganizationName] = useState('');
		const [directorFirstName, setDirectorFirstName] = useState('');
		const [directorLastName, setDirectorLastName] = useState('');
		const [directorMiddleName, setDirectorMiddleName] = useState('');
		const [INN, setINN] = useState('');
		const [KPP, setKPP] = useState('');
		const [OGRN, setOGRN] = useState('');
		const [legalAddress, setLegalAddress] = useState('');

		const handlerOrganizationName = useCallback(
			(e) => setOrganizationName(e.target.value),
			[]
		);
		const handlerDirectorFirstName = useCallback(
			(e) => setDirectorFirstName(e.target.value),
			[]
		);
		const handlerDirectorLastName = useCallback(
			(e) => setDirectorLastName(e.target.value),
			[]
		);
		const handlerDirectorMiddleName = useCallback(
			(e) => setDirectorMiddleName(e.target.value),
			[]
		);
		const handlerINN = useCallback((e) => {
			if (e.target.value.length <= 10) {
				setINN(e.target.value);
			}
		}, []);
		const handlerKPP = useCallback((e) => {
			if (e.target.value.length <= 9) {
				setKPP(e.target.value);
			}
		}, []);
		const handlerOGRN = useCallback((e) => {
			if (e.target.value.length <= 13) {
				setOGRN(e.target.value);
			}
		}, []);
		const handlerLegalAddress = useCallback(
			(e) => setLegalAddress(e.target.value),
			[]
		);

		const handlerOrganizationData = useCallback(() => {
			console.log(55);

			dispatch(
				setOrganization({
					userToken,
					organizationData: {
						organizationName,
						legalAddress,
						inn: INN,
						ogrn: OGRN,
						kpp: KPP,
						directorFirstName,
						directorLastName,
						directorMiddleName,
					},
					handlerError,
					openInformWindow,
				})
			);
		}, [
			userToken,
			organizationName,
			legalAddress,
			INN,
			OGRN,
			KPP,
			directorFirstName,
			directorLastName,
			directorMiddleName,
		]);

		return (
			<FormCard
				title={'Данные организации'}
				classes={s.form}
				submitFunction={handlerOrganizationData}>
				<div className={s.form__inputList}>
					<Input
						classes={s.input}
						inputValue={organizationName}
						changeFunction={handlerOrganizationName}
						placeholder='Название компании*'
						required={true}
					/>

					<Input
						classes={s.input}
						inputValue={directorLastName}
						changeFunction={handlerDirectorLastName}
						placeholder='Фамилия директора*'
						required={true}
					/>

					<Input
						classes={s.input}
						inputValue={directorFirstName}
						changeFunction={handlerDirectorFirstName}
						placeholder='Имя директора*'
						required={true}
					/>

					<Input
						classes={s.input}
						inputValue={directorMiddleName}
						changeFunction={handlerDirectorMiddleName}
						placeholder='Отчество директора'
						required={false}
					/>

					<Input
						classes={s.input}
						type={'number'}
						inputValue={INN}
						changeFunction={handlerINN}
						placeholder='ИНН*'
						required={true}
					/>

					<Input
						classes={s.input}
						type={'number'}
						inputValue={KPP}
						changeFunction={handlerKPP}
						placeholder='КПП*'
						required={true}
					/>

					<Input
						classes={s.input}
						type={'number'}
						inputValue={OGRN}
						changeFunction={handlerOGRN}
						placeholder='ОГРН*'
						required={true}
					/>

					<Input
						classes={s.input}
						inputValue={legalAddress}
						changeFunction={handlerLegalAddress}
						placeholder='Юридический адрес*'
						required={true}
					/>
				</div>

				{error && <div className={s.error}>{error}</div>}
				<Button type='submit' classes={s.btn} text={'Сохранить'} />
			</FormCard>
		);
	}
);
