import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../../../../core/utils/functions/user/userLogout';
import { AdminMenuCard } from './adminMenuCard/adminMenuCard';
import { UserMenuCard } from './userMenuCard/userMenuCard';

export const MenuCard = memo(({ userType, setMenuOpen }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const navigateModeration = useCallback(
		() => handlerNavigate('/adminOffice/moderation'),
		[]
	);
	const navigateLibrary = useCallback(
		() => handlerNavigate('/adminOffice/library'),
		[]
	);
	const navigateUsers = useCallback(
		() => handlerNavigate('/adminOffice/users'),
		[]
	);
	const navigateAdminSupport = useCallback(
		() => handlerNavigate('/adminOffice/support'),
		[]
	);

	const navigateUserData = useCallback(
		() => handlerNavigate('/userOffice/userData'),
		[]
	);
	const navigatePurchases = useCallback(
		() => handlerNavigate('/userOffice/purchases'),
		[]
	);
	const navigateUserSupport = useCallback(
		() => handlerNavigate('/userOffice/support'),
		[]
	);

	const handlerExit = useCallback(() => {
		userLogout({ userType, dispatch });
	}, [userType]);

	function handlerNavigate(path) {
		navigate(path);
		setMenuOpen(false);
	}

	switch (userType) {
		case 1:
		case 2:
			return (
				<AdminMenuCard
					navigateModeration={navigateModeration}
					navigateLibrary={navigateLibrary}
					navigateUsers={navigateUsers}
					navigateSupport={navigateAdminSupport}
					handlerExit={handlerExit}
				/>
			);

		case 0:
		case 3:
		default:
			return (
				<UserMenuCard
					navigateUserData={navigateUserData}
					navigatePurchases={navigatePurchases}
					navigateSupport={navigateUserSupport}
					handlerExit={handlerExit}
				/>
			);
	}
});
