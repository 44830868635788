// import { createSelector } from '@reduxjs/toolkit';

export const selectUser = (state) => state.user;

export const selectUserData = (state) => state.userData;

export const selectOrganizationData = (state) => state.organizationData;

export const selectDocument = (state) => state.document;

export const selectServices = (state) => state.services;
