import { memo, useCallback, useState } from 'react';
import { Input } from './input';

export const InputDate = memo(
	({ classes, inputValue, changeFunction, placeholder, required = false }) => {
		const [type, setType] = useState('text');

		const handlerType = useCallback(() => {
			if (type === 'text') {
				setType(() => 'date');
				return;
			}

			setType(() => 'text');
		}, [type]);

		return (
			<Input
				classes={classes}
				type={type}
				inputValue={inputValue}
				changeFunction={changeFunction}
				focusFunction={handlerType}
				blurFunction={handlerType}
				placeholder={placeholder}
				required={required}
			/>
		);
	}
);
