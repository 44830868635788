import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from '../userInfo.module.scss';
import { selectOrganizationData } from '../../../../../../../store/selectors';
import { DataEditBlock } from '../../../../dataEditBlock/dataEditBlock';
import { InputEditBlock } from '../../../../inputEditBlock/inputEditBlock';
import { UserDataFieldBlock } from '../../../../userDataFieldBlock/userDataFieldBlock';
import { ModalWindow } from '../../../../../../common/modalWindow/modalWindow';
import { InformationWindow } from '../../../../../../common/authentication/informationWindow/informationWindow';
import { OrganizationDataForm } from './organizationDataForm/organizationDataForm';
import { getUserToken } from '../../../../../../../core/utils/functions/user/getUserToken';
import { setUserFullName } from '../../../../../../../store/features/user/thunks';

export const OrganizationInfo = memo(
	({
		user,
		isOpenForm,
		setIsOpenForm,
		isWindowWithForm,
		openInformWindow,
		error,
		handlerError,
	}) => {
		const dispatch = useDispatch();

		const [userToken, setUserToken] = useState(null);
		const { email, phone, firstName, lastName, middleName } = user;
		const {
			organizationName,
			address,
			inn,
			ogrn,
			kpp,
			directorFirstName,
			directorLastName,
			directorMiddleName,
		} = useSelector(selectOrganizationData);

		const [fullName, setFullName] = useState(
			`${lastName} ${firstName} ${middleName}`
		);
		const [directorFullName, setDirectorFullName] = useState(
			`${directorLastName} ${directorFirstName} ${directorMiddleName}`.trim()
		);

		const saveUserFullName = useCallback(
			(value) => {
				if (value === fullName || value === '') return;
				const [lastName, firstName, middleName] = value.split(' ');

				dispatch(
					setUserFullName({
						userToken,
						firstName,
						lastName,
						middleName,
						handlerError,
					})
				);
			},
			[userToken, fullName]
		);

		useEffect(() => {
			setUserToken(getUserToken());
		}, []);

		useEffect(() => {
			if (firstName && lastName) {
				middleName
					? setFullName(`${lastName} ${firstName} ${middleName}`)
					: setFullName(`${lastName} ${firstName}`);
			}
		}, [firstName, lastName, middleName]);

		useEffect(() => {
			if (directorFirstName && directorLastName) {
				directorMiddleName
					? setDirectorFullName(
							`${directorLastName} ${directorFirstName} ${directorMiddleName}`
					  )
					: setDirectorFullName(`${directorLastName} ${directorFirstName}`);
			}
		}, [directorFirstName, directorLastName, directorMiddleName]);

		return (
			<div className={s.userInfo}>
				<DataEditBlock
					title={'Данные организации'}
					isEditForm={true}
					openFormFn={setIsOpenForm}>
					<UserDataFieldBlock
						title={'Название компании'}
						data={organizationName}
					/>
					<UserDataFieldBlock title={'ИНН'} data={inn} />
					<UserDataFieldBlock title={'ФИО директора'} data={directorFullName} />
					<UserDataFieldBlock title={'ОГРН'} data={ogrn} />
					<UserDataFieldBlock title={'КПП'} data={kpp} />
					<UserDataFieldBlock title={'Юридический адрес'} data={address} />
				</DataEditBlock>

				<DataEditBlock title={'Контактная информация'}>
					<InputEditBlock
						title={'Почта'}
						inputType={'email'}
						inputValue={email}
						placeholder={'Введите электронный адрес'}
					/>
					<InputEditBlock
						title={'Номер телефона'}
						inputType={'phone'}
						inputValue={phone}
						placeholder={'Введите номер'}
					/>
					<InputEditBlock
						title={'ФИО представителя'}
						inputValue={fullName}
						placeholder={'Введите ФИО представителя'}
						submitFunction={saveUserFullName}
					/>
				</DataEditBlock>

				{isOpenForm && (
					<ModalWindow clickFunction={setIsOpenForm}>
						{isWindowWithForm ? (
							<OrganizationDataForm
								userToken={userToken}
								openInformWindow={openInformWindow}
								error={error}
								handlerError={handlerError}
							/>
						) : (
							<InformationWindow message={'Данные успешно отправленны.'} />
						)}
					</ModalWindow>
				)}
			</div>
		);
	}
);
