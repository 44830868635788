import { useState, useEffect, useCallback } from 'react';

export const useResize = () => {
	const [width, setWidth] = useState(window.innerWidth);

	const handleResize = useCallback((e) => {
		setWidth(e.target.innerWidth);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return {
		width,
		isDesktop: width > 900,
		isTabled: width > 685,
		isPhone: width <= 685,
	};
};
