import { memo } from 'react';
import s from './modalWindow.module.css';

export const ModalWindow = memo((props) => {
	// console.log(clickFunction);
	return (
		<div
			className={s.container}
			// onClick={clickFunction}
		>
			<div
				className={`${s.window} ${props.classes}`}
				onClick={(e) => e.stopPropagation()}>
				<div className={s.childrenContainer}>{props.children}</div>

				{props.clickFunction !== undefined && (
					<div className={` ${s.cross}`} onClick={props.clickFunction}></div>
				)}
			</div>
		</div>
	);
});
